import { Box } from '@mui/material';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../../constants/ResourceSupport';
import { useTranslation } from '../../../hooks/useKeyedTranslation';
import { keyGenerator } from '../../../utils';
import ResourceCard from './ResourceCard';

interface ResourceCardsProps {
  resources: Card[];
}

const ResourceCards = ({ resources: RESOURCES }: ResourceCardsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        flexWrap="wrap"
        justifyContent="start"
      >
        {RESOURCES.map((resource) => (
          <ResourceCard
            key={keyGenerator(resource.id)}
            resource={resource}
            onClickCardHandler={() => navigate(resource.id)}
          >
            {t(resource.description)}
          </ResourceCard>
        ))}
      </Box>
    </Box>
  );
};

ResourceCards.displayName = 'ResourceCards';

export default memo(ResourceCards);
