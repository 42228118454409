import { debounce } from 'lodash';
import { CAD_POSTAL_CODE_REG } from '../constants/regex';

const DEBOUNCE_TIME = 500;

export const isValidPostalCode = (postalCode: string): boolean => {
  return CAD_POSTAL_CODE_REG.test(postalCode);
};

/**
 * Debounced function which checks if the provided postal code is valid
 * and then optionally triggers a callback function with the validation result.
 *
 * @name debouncedIsValidPostalCode
 * @function
 * @param {string} postalCode - The string representation of the postal code to be validated.
 * @param {Function} [cb] - An optional callback function which gets invoked with the result of the validation. This allows the caller to define an action to be performed based on the result of the postal code validation. If no callback is provided, the function will just validate the postal code without any further actions.
 * @returns {void}
 *
 * @example
 * debouncedIsValidPostalCode('n6g5j8', (isValid) => {
 *   if (isValid) {
 *     console.log('The postal code is valid.');
 *   } else {
 *     console.log('The postal code is not valid.');
 *   }
 * });
 *
 */

export const debouncedIsValidPostalCode = debounce(
  (postalCode: string, cb?: (isValid: boolean) => unknown) => {
    const isValid = isValidPostalCode(postalCode);

    if (!cb) {
      return;
    }

    cb(isValid);
  },
  DEBOUNCE_TIME
);
