import { Grid } from '@mui/material';
import _ from 'lodash';
import { memo } from 'react';
import ClinicLinkDetails from './ClinicLinkDetails';

interface ClinicPhoneDetailsFactoryProps {
  details: string[];
}

export const ClinicPhoneDetailsFactory = memo(
  ({ details }: ClinicPhoneDetailsFactoryProps) => {
    return (
      <Grid container rowSpacing={0.75}>
        {_.map(details, (phoneURI, index) => (
          <Grid item xs={12} key={index}>
            <ClinicLinkDetails
              href={`tel:${phoneURI}`}
              details={phoneURI.split(';').join(' x ')}
              tooltipI18n="find_clinic.tooltip.phone_button"
            />
          </Grid>
        ))}
      </Grid>
    );
  }
);
ClinicPhoneDetailsFactory.displayName = 'ClinicPhoneDetailsFactory';

export default ClinicPhoneDetailsFactory;
