import { ComponentsOverrides } from '@mui/material';

const styleOverrides: ComponentsOverrides['MuiPaper'] = {
  root: {
    // When inside the patients-table div
    '.patients-table &, .call-requests-table &, .surveys-table &': {
      boxShadow: 'unset',
    },

    'header & div.header-tabs': {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, 0%)',
    },
  },
};

export const MuiPaper = {
  styleOverrides,
};
