import { Typography, TypographyProps, styled } from '@mui/material';

export const StyledAccordionTypography = styled((props: TypographyProps) => (
  <Typography component="span" {...props} />
))(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '1.375rem',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight: '1.625rem',
}));
