import { createContext, MutableRefObject } from 'react';

interface ScrollContextType {
  refs: (MutableRefObject<HTMLElement | null> | undefined)[];
  scrollToItem: (index: number) => void;
  addRef: (
    index: number,
    newRefs: MutableRefObject<HTMLElement | null>
  ) => void;
  removeRef: (index: number) => void;
  selectedRef: number | null;
  setSelectedRef: (id: number | null) => void;
}

export const ScrollContext = createContext<ScrollContextType>({
  refs: [],
  scrollToItem: () => {},
  addRef: () => {},
  removeRef: () => {},
  selectedRef: null,
  setSelectedRef: () => {},
});

export default ScrollContext;
