import { MutableRefObject, ReactNode, useCallback, useState } from 'react';
import { ScrollContext } from './ScrollContext';

interface ScrollContextProviderProps {
  children: ReactNode;
}

export const ScrollContextProvider = ({
  children,
}: ScrollContextProviderProps) => {
  const [refs, setRefs] = useState<
    (MutableRefObject<HTMLElement | null> | undefined)[]
  >([]);
  const [selectedRefID, setSelectedRefID] = useState<number | null>(null);

  const scrollToItem = useCallback(
    (index: number) => {
      const ref = refs[index]?.current;
      if (!ref) return;

      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    },
    [refs]
  );

  const addRef = useCallback(
    (index: number, newRef: MutableRefObject<HTMLElement | null>) => {
      setRefs((prevRefs) => {
        const newRefs = [...prevRefs];
        newRefs[index] = newRef;
        return newRefs;
      });
    },
    []
  );

  const removeRef = useCallback((index: number) => {
    setRefs((prevRefs) => [
      ...prevRefs.slice(0, index),
      undefined,
      ...prevRefs.slice(index + 1),
    ]);
  }, []);

  return (
    <ScrollContext.Provider
      value={{
        refs,
        selectedRef: selectedRefID,
        scrollToItem,
        addRef,
        removeRef,
        setSelectedRef: setSelectedRefID,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollContextProvider;
