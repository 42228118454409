import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { memo } from 'react';

import BackgroundImage from '../assets/RAAM_1FD_background.webp';

const GradientOverlay = styled('div')({
  content: '""',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '300px',
  background:
    'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
  zIndex: -1,
});

const HeroImageContainer = styled(Box)(() => ({
  position: 'fixed',
  height: '100vh',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${BackgroundImage})`,
  backgroundPosition: 'left top',
  width: '100vw',
  top: 0,
  zIndex: -2,
}));

const ClinicHeroImageBackground = memo(() => {
  return (
    <HeroImageContainer>
      <GradientOverlay />
    </HeroImageContainer>
  );
});

ClinicHeroImageBackground.displayName = 'ClinicHeroImageBackground';

export default ClinicHeroImageBackground;
