import { MutableRefObject, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { clearLocationParams } from '../utils';
import { useClinicFilterContext } from './useClinicFilterContext';
import { useFindClinicContext } from './useFindClinicContext';

/** If the browser supports geolocation, returns a function that attempts to set the coordinates from the FindClinicContext to the user's current location. */
export const useGeoLocationCallback = () => {
  const { setCoordinates } = useFindClinicContext();
  const [, setSearchParams] = useSearchParams();
  const { updateClinicFilter } = useClinicFilterContext();

  const getGeoLocation = useCallback(
    (
      /** Clear the search parameters and reset the clinic filter. */
      updateContexts?: boolean,
      executeCallback?: MutableRefObject<boolean>,
      /** Will be provided with coordinates if given access to the user's location, otherwise undefined. */
      callback?: (
        coords: google.maps.LatLngLiteral | GeolocationPositionError
      ) => void
    ) => {
      const updatePosition: PositionCallback = (userLocation) => {
        if (executeCallback && !executeCallback.current) {
          return;
        }

        const location = {
          lat: userLocation.coords.latitude,
          lng: userLocation.coords.longitude,
        };

        setCoordinates(location);

        if (updateContexts) {
          setSearchParams(clearLocationParams, { replace: true });
          updateClinicFilter({ type: 'reset' });
        }

        callback?.(location);
      };

      const options: PositionOptions = {
        // https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition#enablehighaccuracy
        // Probably not needed, we just want a quick rough estimate.
        enableHighAccuracy: false,
      };

      navigator.geolocation.getCurrentPosition(
        updatePosition,
        callback,
        options
      );
    },
    [setCoordinates, setSearchParams, updateClinicFilter]
  );

  if ('geolocation' in navigator) {
    return getGeoLocation;
  }
};
