import { Box, Typography, styled } from '@mui/material';
import { extractVideoId } from '../utils';

const DEFAULT_VIDEO_WIDTH = 800;
const DEFAULT_VIDEO_HEIGHT = 445;

const VideoContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  height: '240px',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',

  [theme.breakpoints.up('sm')]: {
    width: DEFAULT_VIDEO_WIDTH,
    height: DEFAULT_VIDEO_HEIGHT,
    paddingTop: 0,
  },
}));

const ResponsiveIframe = styled('iframe')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',

  [theme.breakpoints.up('sm')]: {
    width: DEFAULT_VIDEO_WIDTH,
    height: DEFAULT_VIDEO_HEIGHT,
  },
}));

interface YouTubeVideoProps {
  link: string;
  height?: number | string;
  width?: number | string;
}

const YouTubeVideo = ({ link, width, height }: YouTubeVideoProps) => {
  // Extract the video ID from the YouTube link
  const videoId = extractVideoId(link);

  if (videoId === '') {
    // Invalid YouTube link
    console.log('Invalid YouTube link');
    return (
      <Box>
        <Typography variant="body1">Invalid YouTube link</Typography>
      </Box>
    );
  }

  return (
    <VideoContainer>
      <ResponsiveIframe
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube Video"
        allowFullScreen
        width={width}
        height={height}
      ></ResponsiveIframe>
    </VideoContainer>
  );
};

export default YouTubeVideo;
