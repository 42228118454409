export const ROUTE_URL = {
  base: '/',
  find_clinic: {
    index: '/find-clinic',
    results: 'results',
  },
  connect_now: {
    index: '/connect-now',
    initial_assessment: 'initial-assessment',
    select_clinic: 'select-clinic',
    find_clinic_results: 'find-clinic/results',
    questionId: ':questionId',
  },
  resources_support: {
    index: '/resources-and-support',
    raam_clinic: '?open=raam-clinic',
    for_patients: '?open=for-patients',
    for_families: '?open=for-families',
    for_professionals: '?open=for-professionals',
    resourceId: ':resourceId',
  },
};
