import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

export const StyledCardText = styled(Typography)(() => ({
  textOverflow: 'ellipsis',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.3125rem',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 7,
  WebkitBoxOrient: 'vertical',
}));
