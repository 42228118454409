import { Dispatch, ReactNode, useCallback, useState } from 'react';
import { getCoordinatesFromPostalCode } from '../../services/geolocationService';
import { CLINIC_DATA, ClinicDataType, haversineDistance } from '../../utils'; //TODO: replace with actual data
import FindClinicContext from './FindClinicContext';

interface FindClinicContextProviderProps {
  children: ReactNode;
}

export const FindClinicContextProvider = ({
  children,
}: FindClinicContextProviderProps) => {
  const [coordinates, originalSetCoordinates] = useState<
    google.maps.LatLng | google.maps.LatLngLiteral | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clinicData, setClinicData] = useState(CLINIC_DATA);

  const getClinicDistance = useCallback(
    (clinic?: ClinicDataType) => {
      if (coordinates && clinic) {
        return haversineDistance(coordinates, clinic);
      } else {
        return 0;
      }
    },
    [coordinates]
  );

  const setCoordinates = useCallback<
    Dispatch<google.maps.LatLng | google.maps.LatLngLiteral | null>
  >((value) => {
    if (value) {
      const clinicDistanceComparator = (
        a: ClinicDataType,
        b: ClinicDataType
      ) => {
        // Returns how much further a is than b
        return haversineDistance(value, a) - haversineDistance(value, b);
      };
      setClinicData([...CLINIC_DATA].sort(clinicDistanceComparator));
    }
    originalSetCoordinates(value);
  }, []);

  const setCoordFromPostalCode = useCallback(
    async (postalCode: string) => {
      setIsLoading(true);

      try {
        setCoordinates(await getCoordinatesFromPostalCode(postalCode));
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setCoordinates]
  );

  return (
    <FindClinicContext.Provider
      value={{
        coordinates,
        setCoordinates,
        isLoading,
        setIsLoading,
        setCoordFromPostalCode,
        clinicData,
        getClinicDistance,
      }}
    >
      {children}
    </FindClinicContext.Provider>
  );
};

export default FindClinicContextProvider;
