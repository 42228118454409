import { Stack, Typography } from '@mui/material';
import { useConnectionState } from 'hive-react-utils';
import { useTranslation } from 'react-i18next';

export default function NotImplemented(): JSX.Element {
  const connectionState = useConnectionState();
  const { t } = useTranslation();

  // TODO
  return (
    <Stack flexGrow="1" justifyContent="center" alignItems="center">
      <Typography color="primary">
        <>{t('not_implemented', { connectionState })}</>
      </Typography>
    </Stack>
  );
}
