import { Typography, TypographyProps, styled } from '@mui/material';

export const StyledInnerAccordionTypography = styled(
  (props: TypographyProps) => <Typography component="span" {...props} />
)(({ theme }) => ({
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  color: theme.palette.primary.main,
}));
