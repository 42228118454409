const APP_TITLE = 'RAAM One Front Door';

export const fr = {
  app: {
    title: APP_TITLE,
  },
  header: {
    title: APP_TITLE,
    login: 'Connexion',
    logout: 'Déconnexion',
  },
  footer: {
    body_1: "RAAM n'est pas un service d'urgence.",
    body_2:
      "Si vous êtes en situation d'urgence, veuillez vous rendre au service des urgences le plus proche ou appeler le 911.",
  },
  signin: {
    please_sign_in: 'Veuillez vous connecter...',
    title: 'Connexion',
    message:
      "Utilisez vos informations d'identification pour vous connecter à la salle d'attente virtuelle.",
    email_label: 'Email:',
    email_placeholder: 'veuillez entrer votre adresse e-mail',
    password_label: 'Mot de passe:',
    password_placeholder: 'veuillez entrer le mot de passe',
    remember_me: 'Se souvenir de moi',
    button_label: 'Connexion',
    forgot_password: 'Mot de passe oublié?',
  },
  forgot_password: {
    title: 'Mot de passe oublié',
    message:
      'Entrez votre courriel pour commencer pour réinitialiser votre mot de passe.',
    email_label: 'Email:',
    email_placeholder: 'veuillez entrer votre adresse e-mail',
    button_label: {
      cancel: 'Annuler',
      submit: 'Soumettre',
    },
    success:
      "Un email a été envoyé à l'adresse {{email}} avec les instructions pour modifier votre mot de passe.",
  },
  change_password: {
    title: 'Modifier le mot de passe',
    message: 'Nouveau mot de passe',
    password_label: 'Mot de passe',
    confirm_password_label: 'Confirmez votre mot de passe',
    button_label: {
      cancel: 'Annuler',
      change: 'Modifier le mot de passe',
    },
    success:
      'Vous pouvez maintenant |vous connecter| avec votre nouveau mot de passe.',
    success_sign_in: 'Vous Connecter',
    error: {
      min: 'Le mot de passe doit contenir au moins 8 caractères',
      lowercase:
        'Le mot de passe doit contenir au moins un caractère minuscule',
      uppercase:
        'Le mot de passe doit contenir au moins un caractère majuscule',
      digits: 'Le mot de passe doit contenir au moins un chiffre',
      spaces: "Le mot de passe ne doit pas contenir d'espaces",
    },
  },
  language_toggle: {
    aria_label: 'Changer la langue en anglais',
    title: 'Changer la langue en anglais',
  },
  find_clinic: {
    description: {
      based: 'En fonction de votre emplacement actuel',
      filter: 'et des filtres sélectionnés',
      zero: 'il y a <strong>0 clinique RAAM</strong>.',
      one: 'il y a <strong>1 clinique RAAM</strong>.',
      many: 'il y a <strong>{{count}} cliniques RAAM</strong>.',
    },
    button: {
      filter: {
        label: 'Filtrer les résultats de One Front Door',
        distance: 'Distance par rapport à mon emplacement...',
        clinic_access: 'Accès aux cliniques',
        switches: {
          acceptsVirtual: 'Afficher seulement consultations virtuelles',
          isOpen: 'Afficher uniquement les cliniques ouvertes',
        },
      },
      location: {
        label: "Changer d'emplacement",
        description:
          "Pour voir les cliniques d'une autre région, entrez un code postal",
        postal_code: 'Code Postal',
        automatic: 'Utilisez ma localisation pour trouver des cliniques',
        cancel: 'Annuler',
        continue: 'Continuer',
      },
      check_in_button_label: 'Se connecter maintenant',
      clinic_status_icon: {
        open: 'La clinique est ouverte',
        closed: 'La clinique est fermée',
      },
    },
    tooltip: {
      link_button: 'Ouvrir le lien dans un nouvel onglet',
      phone_button: "Ouvrir le lien vers le numéro d'appel",
      location_button: "Ouvrir le lien pour voir l'emplacement",
      check_in_button: 'Connectez-vous pour une visite virtuelle',
    },
    input_location: {
      header: 'Trouvez une clinique RAAM près de chez vous',
      body: "Afin de vous mettre en contact avec l'équipe clinique la plus appropriée, nous avons besoin de savoir où vous résidez.",
      placeholder: 'Saisir le code postal (par exemple, N6G 1G9)',
      placeholder_small: 'Saisir le code postal',
      helper_text:
        'Entrez votre code postal pour trouver les cliniques RAAM les plus proches de chez vous.',
      error_text: 'Veuillez saisir un code postal valable.',
      next_step: 'Continuer',
      button_aria_label: 'Soumettre le code postal',
      automatic: 'Utilisez ma localisation',
      info: {
        available:
          'Les services de localisation sont activés pour ce site. Nous utiliserons la localisation actuelle de votre appareil pour trouver des cliniques dans votre région. Vos données de localisation ne seront pas conservées et ne seront pas utilisées à des fins de suivi.',
        denied:
          'Les services de localisation sont désactivés pour ce site. Veuillez réinitialiser les autorisations dans les paramètres de votre application.',
        unavailable:
          "Nous n'avons pas été en mesure de déterminer votre emplacement en raison d'une erreur inconnue.",
        timeout:
          'La demande de récupération de votre position a dépassé le temps imparti. Veuillez réessayer ou entrer votre code postal manuellement.',
        insecure:
          "Nous ne sommes pas en mesure de déterminer votre emplacement car cette page n'a pas été servie par HTTPS.",
        waiting:
          "Nous attendons que votre navigateur nous donne accès à la localisation. Si vous voyez une fenêtre contextuelle vous demandant l'autorisation de le faire, veuillez l'accepter.",
      },
      geolocation_wait_aria_label: "En attente d'un accès à la géolocalisation",
    },
    seeking_support_check_in: {
      button: {
        aria_label:
          'Se connecter maintenant à une clinique RAAM pour obtenir des services de soutien.',
        title:
          'Se connecter maintenant à une clinique RAAM pour obtenir des services de soutien.',
        label: 'Enregistrement',
      },
      support_message:
        "Si vous êtes à la recherche de services de soutien, veuillez vous connecter maintenant, et vous serez mis en relation avec l'un des membres de l'équipe de notre réseau de conseil virtuel.",
      emergency_message_primary: "RAAM n'est pas un service d'urgence.",
      emergency_message_secondary:
        "En cas d'urgence, veuillez vous rendre au service des urgences le plus proche ou appeler le 911.",
    },
    postal_code_errors: {
      not_found: 'Code postal non trouvé.',
      unreachable: 'La demande de géocodage a échoué.',
    },
    clinic_icon_legend: {
      title: 'Clinique Virtuelle:',
      open: 'Ouvert',
      closed: 'Fermé',
    },
  },
  not_implemented: 'État "{{connectionState}}" non-implanté',
  homepage: "page d'accueil",
  landing: {
    title: 'Bienvenue à AccessRAAM Ontario',
    subheading: 'Qu’est-ce que AccessRAAM?',
    body: 'Access RAAM facilite la recherche de soutien, de services et de soins en matière de santé mentale et/ou de toxicomanie. Nous éliminons les conjectures et travaillons avec vous pour vous orienter vers les services dont vous avez besoin, tout en vous soutenant tout au long du processus. Après avoir pris contact avec nous, vous serez mis en relation avec un professionnel de la santé mentale, de la toxicomanie et des addictions qui vous orientera vers les services dont vous avez besoin au sein d’un réseau d’organisations partenaires.',
    cards: {
      additional_resources: {
        header: 'Vous cherchez des informations?',
        body: 'Vous recherchez des informations ou des ressources pour vous ou pour une personne de votre entourage ayant des problèmes de consommation de substances psychoactives.',
        link_button: {
          label: 'En savoir plus',
        },
        raam_clinic_network: {
          link_button: {
            label: 'Réseau de cliniques RAAM',
          },
          body: 'est un réseau provincial de cliniques offrant des services de consultation en personne, par téléconférence ou virtuellement pour la toxicomanie et la santé mentale.',
        },
        for_patients: {
          link_button: {
            label: 'Pour les patients, en savoir plus',
          },
          body: 'sur le sevrage, le traitement, la thérapie, les meilleures pratiques, les histoires personnelles et l’obtention d’aide.',
        },
        for_families: {
          link_button: {
            label: 'Pour les familles, les amis et les proches',
          },
          body: 'pour en savoir plus sur la consommation de substances, la santé mentale et les services d’aide.',
        },
        for_professionals: {
          link_button: {
            label: 'Pour les professionnels',
          },
          body: 'pour en savoir plus sur l’usage de substances, la santé mentale et l’orientation vers des services spécialisés.',
        },
      },
      find_clinic: {
        header: 'Trouvez une clinique RAAM près de chez vous',
        body: 'Vous cherchez une clinique RAAM dans votre région pour une consultation sans rendez-vous ou virtuelle ? Indiquez-nous simplement où vous vous trouvez et nous vous indiquerons les cliniques les plus proches.',
        button: {
          label: 'Trouver une clinique',
          title: 'Cliquez maintenant pour trouver une clinique RAAM',
          aria_label: 'Cliquez maintenant pour trouver une clinique RAAM',
        },
        image: {
          alt: 'Carte de cliniques RAAM',
        },
      },
      connect_now: {
        header: 'Vous mettre en relation avec l’aide dont vous avez besoin',
        subheading1: 'Trouver une clinique près de chez vous',
        body1:
          'Cliquez sur le bouton "Se connecter maintenant" ci-dessous pour voir les partenaires de service disponibles près de chez vous pour une consultation en ligne immédiate ou pour trouver un partenaire de service en personne près de chez vous.',
        subheading2: 'Se connecter maintenant pour une consultation',
        body2:
          'Remplissez un formulaire en ligne simple et sécurisé pour identifier les services dont vous avez besoin, et mettez-vous dans la file d’attente pour une réunion virtuelle avec l’un des consultants de notre réseau.',
        subheading3: 'Obtenez l’aide dont vous avez besoin',
        body3:
          'Lors de votre consultation, vous pouvez rencontrer plusieurs consultants en un seul appel afin de vous assurer que vous obtenez les services et le soutien dont vous avez besoin en un seul endroit.',
        button: {
          label: 'Se connecter',
          title:
            'Cliquez maintenant pour entrer en contact avec une clinique RAAM',
          aria_label:
            'Cliquez maintenant pour entrer en contact avec une clinique RAAM',
        },
      },
      whosAccessRAAM: {
        header: 'À qui s’adresse AccessRAAM?',
        body1: 'AccessRAAM s’adresse à tous:',
        points: {
          point1: '16 ans et plus',
          point2: 'actuellement situé en Ontario',
          point3: 'et/ou des services de santé et de toxicomanie.',
        },
        body2:
          'Si vous avez besoin d’aide et de soutien et que vous ne savez pas où vous adresser, contactez-nous et nous vous mettrons en contact avec le service dont vous avez besoin.',
      },
      whoAreWe: {
        header: 'Qui sommes-nous?',
        body: 'La Rapid Access Addiction Medicine (RAAM) Clinic offre des services aux personnes qui s’inquiètent de leur consommation d’alcool et/ou d’opioïdes. Nous vous aidons en:',
        points: {
          point1: 'examinant avec vous vos objectifs de traitement,',
          point2:
            'en fournissant des médicaments (si nécessaire) pour les problèmes d’opioïdes et/ou d’alcool,',
          point3:
            'en offrant de brefs conseils individuels, et en vous mettant en contact avec les ressources communautaires adaptées à vos besoins.',
        },
      },
    },
  },
  connect_now: {
    header:
      'Avez-vous consulté une clinique RAAM - en personne, par téléphone ou en ligne - au cours des six derniers mois, ou avez-vous une relation suivie avec une clinique RAAM?',
    yes_button: {
      label: 'Oui',
      title: 'Oui',
      aria_label: 'Oui',
    },
    no_button: {
      label: 'Non',
      title: 'Non',
      aria_label: 'Non',
    },
    continue_button: {
      label: 'Continuer',
      title: 'Continuer',
      aria_label: 'Continuer',
    },
    select_clinic: {
      header:
        'Lorsque vous avez visité la clinique RAAM en personne ou virtuellement, quelle clinique avez-vous visitée, ou si vous avez une relation avec une clinique, veuillez la sélectionner?',
      label: 'Sélectionner la clinique RAAM',
    },
    find_clinic: {
      header:
        'Afin de vous mettre en contact avec l’équipe clinique la plus appropriée, nous avons besoin de connaître votre lieu de résidence.',
    },
  },
  visited_clinic: {
    header: 'Vous êtes déjà venu ici',
    body: 'La dernière fois que vous êtes venu ici, vous vous êtes connecté avec {{previousClinic}}, aimeriez-vous vous reconnecter avec eux maintenant?',
    close_button: {
      title: 'Fermer le dialogue',
      aria_label: 'Fermer le dialogue',
    },
  },
  resource_support: {
    title: 'Ressources et soutien',
    body1:
      'Le Programme de traitement de la toxicomanie et des troubles concomitants du Royal s’adresse aux personnes vivant avec des problèmes complexes de toxicomanie (drogues ou alcool), de santé mentale et de santé physique. Ce programme offre une gamme de services d’hospitalisation et de consultation externe aux personnes atteintes de problèmes de toxicomanie et de santé mentale, qui, ensemble, sont appelés des troubles concomitants.',
    body2:
      'Nous fournissons des services spécialisés fondés sur des données probantes afin d’aider les clients et leurs proches à optimiser leur santé et leur mieux-être. Nous travaillerons avec chaque client pour comprendre ses objectifs et déterminer le meilleur traitement et les meilleurs soutiens à lui offrir pour qu’il atteigne ces objectifs.',
    raam_clinic_network: {
      title: 'Partenariat RAAM Digital Front Door (DFD)',
      about: {
        title: 'À propos du partenariat RAAM Digital Front Door (DFD)',
        description:
          'Aenean lacinia bibendum nulla sed consectetur. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec ullamcorper nulla non metus auctor fringilla. Nullam id dolor id nibh ultricies vehicula ut id elit. Nulla vitae elit libero, a pharetra augue. Maecenas sed diam eget risus varius blandit sit amet non magna.',
      },
      find_clinic: {
        title: 'Toutes les cliniques en Ontario',
        description:
          'Donec sed odio dui. Donec id elit non mi porta gravida at eget metus. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Maecenas faucibus mollis interdum.',
      },
      partners: {
        title: 'Les partenaires de la RAAM DFD',
        description:
          'Cras mattis consectetur purus sit amet fermentum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor.',
      },
    },
    for_patients: {
      title: 'Pour les patients',
      general: {
        title: 'Comprendre la consommation de substances',
        description:
          "De nombreuses personnes considèrent les problèmes de consommation de substances uniquement sous l'angle de la dépendance, une dépendance à l'alcool ou à d'autres drogues qui oblige la personne à consommer continuellement la substance pour se sentir normale. L'usage de substances est plus vaste que cela.",
        accordion: {
          alcohol: {
            title: 'Alcool',
            cards: {
              download: {
                title: 'Titre de la ressource téléchargeable',
                description:
                  'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
                link: 'Titre du lien du document',
              },
              video: {
                title: 'Titre de la ressource vidéo',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras mattis consectetur purus sit amet fermentum.',
                link: 'Titre du lien vidéo',
              },
              article: {
                title: "Titre de la ressource de l'article",
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras mattis consectetur purus sit amet fermentum.',
                link: "Lire l'article",
              },
              external: {
                title: 'Titre de la ressource externe',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.',
                link: 'Titre du lien externe',
              },
            },
          },
          opioids: {
            title: 'Opioïdes',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          benzodiazepines: {
            title: 'Benzodiazépines',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
      alcohol: {
        title: "Consommation d'alcool",
        description:
          'Donec sed odio dui. Donec id elit non mi porta gravida at eget metus. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Maecenas faucibus mollis interdum.',
        accordion: {
          withdrawal: {
            title: 'Gestion des retraits',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          treatment: {
            title: 'Traitement',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          best_practices: {
            title: 'Meilleures pratiques',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          personal_stories: {
            title: 'Histoires personnelles',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
      opioids: {
        title: "Consommation d'opioïdes",
        description:
          'Donec sed odio dui. Donec id elit non mi porta gravida at eget metus. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Maecenas faucibus mollis interdum.',
        accordion: {
          withdrawal: {
            title: 'Gestion des retraits',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          agonist: {
            title: 'Traitement par agonistes opioïdes',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          treatment: {
            title: 'Traitement',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          best_practices: {
            title: 'Meilleures pratiques',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          personal_stories: {
            title: 'Histoires personnelles',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
      mental_health: {
        title: 'Santé mentale',
        description:
          'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Maecenas faucibus mollis interdum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.',
        accordion: {
          concurrent_disorders: {
            title: 'Troubles concomitants',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
      getting_help: {
        title: "Obtenir de l'aide",
        description:
          'Cras mattis consectetur purus sit amet fermentum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Nullam quis risus eget urna mollis ornare vel eu leo.',
        accordion: {
          emergency: {
            title: "Quand faire appel aux services d'urgence",
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          crisis: {
            title: "Numéros de la ligne de crise et de la ligne d'assistance",
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          raam: {
            title: "Qu'est-ce que RAAM ?",
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          dfd: {
            title: "Qu'est-ce que le DFD ?",
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
    },
    for_families: {
      title: 'Pour les familles, les amis et les proches',
      general: {
        title: 'Comprendre la consommation de substances',
        description:
          "De nombreuses personnes considèrent les problèmes de consommation de substances uniquement sous l'angle de la dépendance, une dépendance à l'alcool ou à d'autres drogues qui oblige la personne à consommer continuellement la substance pour se sentir normale. L'usage de substances est plus vaste que cela.",
        accordion: {
          alcohol: {
            title: 'Alcool',
            cards: {
              download: {
                title: 'Titre de la ressource téléchargeable',
                description:
                  'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
                link: 'Titre du lien du document',
              },
              video: {
                title: 'Titre de la ressource vidéo',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras mattis consectetur purus sit amet fermentum.',
                link: 'Titre du lien vidéo',
              },
              article: {
                title: "Titre de la ressource de l'article",
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras mattis consectetur purus sit amet fermentum.',
                link: "Lire l'article",
              },
              external: {
                title: 'Titre de la ressource externe',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.',
                link: 'Titre du lien externe',
              },
            },
          },
          opioids: {
            title: 'Opioïdes',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          benzodiazepines: {
            title: 'Benzodiazépines',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
      mental_health: {
        title: 'Santé mentale',
        description:
          'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Maecenas faucibus mollis interdum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.',
        accordion: {
          concurrent_disorders: {
            title: 'Troubles concomitants',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
      getting_help: {
        title: "Obtenir de l'aide",
        description:
          'Cras mattis consectetur purus sit amet fermentum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Nullam quis risus eget urna mollis ornare vel eu leo.',
        accordion: {
          emergency: {
            title: "Quand faire appel aux services d'urgence",
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          crisis: {
            title: "Numéros de la ligne de crise et de la ligne d'assistance",
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          raam: {
            title: "Qu'est-ce que RAAM ?",
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          dfd: {
            title: "Qu'est-ce que le DFD ?",
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
    },
    for_professionals: {
      title: 'Pour les professionnels',
      general: {
        title: 'Comprendre la consommation de substances',
        description:
          "De nombreuses personnes considèrent les problèmes de consommation de substances uniquement sous l'angle de la dépendance, une dépendance à l'alcool ou à d'autres drogues qui oblige la personne à consommer continuellement la substance pour se sentir normale. L'usage de substances est plus vaste que cela.",
        accordion: {
          alcohol: {
            title: 'Alcool',
            cards: {
              download: {
                title: 'Titre de la ressource téléchargeable',
                description:
                  'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
                link: 'Titre du lien du document',
              },
              video: {
                title: 'Titre de la ressource vidéo',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras mattis consectetur purus sit amet fermentum.',
                link: 'Titre du lien vidéo',
              },
              article: {
                title: "Titre de la ressource de l'article",
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras mattis consectetur purus sit amet fermentum.',
                link: "Lire l'article",
              },
              external: {
                title: 'Titre de la ressource externe',
                description:
                  'Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.',
                link: 'Titre du lien externe',
              },
            },
          },
          opioids: {
            title: 'Opioïdes',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
          benzodiazepines: {
            title: 'Benzodiazépines',
            description:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
          },
        },
      },
      mental_health: {
        title: 'Santé mentale',
        description:
          'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Maecenas faucibus mollis interdum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.',
      },
      referrals: {
        title: 'Renvois',
        description:
          'Cras mattis consectetur purus sit amet fermentum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Nullam quis risus eget urna mollis ornare vel eu leo.',
      },
    },
  },
  lorem: {
    short: 'Lorem ipsum',
    medium:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam nisi numquam consequatur cum optio doloremque praesentium blanditiis rem aliquid harum fugit quam assumenda quaerat quod natus, quo sint dolorum tempore.',
    long: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nisi sapien, dapibus in ultrices vitae, gravida a sapien. Nullam eu lorem convallis, convallis orci eget, tempus ipsum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla facilisi. Nunc orci diam, efficitur ac est eu, varius porttitor nisl. Aenean bibendum semper nulla, eget sollicitudin orci dignissim id. Curabitur hendrerit hendrerit dui, vel egestas diam bibendum sit amet. Pellentesque aliquam bibendum turpis, nec blandit enim posuere id. Ut pulvinar leo sit amet facilisis porta. Pellentesque tempor lacus at lorem dignissim volutpat. Vestibulum sed odio non velit pulvinar mattis. Vestibulum a iaculis massa. Curabitur ac urna aliquam metus suscipit viverra id non elit.',
  },
  resource_card: {
    link: {
      pdf: {
        label: 'Télécharger le pdf',
      },
      resource: {
        label: 'Lire l’article',
      },
      video: {
        label: 'Voir la vidéo',
      },
    },
  },
  common: {
    button: {
      close: {
        label: 'Fermer',
        title: 'Fermer',
        aria_label: 'Fermer',
      },
      back: {
        label: 'Retour',
        title: 'Retour',
        aria_label: 'Retour',
      },
    },
  },
};
