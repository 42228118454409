import { Skeleton, SkeletonProps } from '@mui/material';
import { memo } from 'react';

interface RectangularWaveSkeletonProps extends SkeletonProps {
  width: string;
  height: string;
}

type RestProps = Omit<SkeletonProps, keyof RectangularWaveSkeletonProps>; //ensure rest props are of type SkeletonProps

function RectangularWaveSkeleton({
  width,
  height,
  ...restProps
}: RectangularWaveSkeletonProps & RestProps) {
  return (
    <Skeleton
      variant="rectangular"
      animation="wave"
      width={width}
      height={height}
      {...restProps}
    />
  );
}

export default memo(RectangularWaveSkeleton);
