import { Grid, GridProps } from '@mui/material';
import _ from 'lodash';
import { memo } from 'react';
import { ClinicDataType, keyGenerator } from '../../../../utils';
import { ClinicDetailRowFactory } from './ClinicDetailRowFactory';
import { DETAIL_SCHEMAS } from './ClinicListSchema';

interface ClinicDetailListProps {
  clinic: ClinicDataType;
  rows: (keyof typeof DETAIL_SCHEMAS)[];
}

const ClinicDetailList = ({
  clinic,
  rows,
  ...gridProps
}: ClinicDetailListProps & Partial<GridProps>) => {
  return (
    <Grid container columns={10} rowSpacing={{ xs: '12px' }} {...gridProps}>
      {_.map(_.pick(DETAIL_SCHEMAS, ...rows), (clinicSchema, index) => (
        <ClinicDetailRowFactory
          clinic={clinic}
          clinicSchema={clinicSchema}
          key={keyGenerator(clinic.name, index)}
        />
      ))}
    </Grid>
  );
};

export default memo(ClinicDetailList);
