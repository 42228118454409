import { Close as CloseIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';
import { memo, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { LOCALSTORAGE_KEYS } from '../../constants/localStorage';
import { ROUTE_URL } from '../../constants/routes';
import { useTranslation } from '../../hooks/useKeyedTranslation';
import { DFDClinicDataType } from '../../utils';
import { Transition } from '../Transition';

const ButtonGroupContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(0),
}));

const StyledHeaderText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '1.4rem',
  fontWeight: '600',
  color: theme.palette.primary.main,
  flex: 1,
  marginLeft: theme.spacing(2),
}));

const StyledAppBar = styled(AppBar)(() => ({
  position: 'relative',
  color: 'inherit',
  backgroundColor: 'white',
  boxShadow: 'none',
}));

interface RAAMClinicReconnectionPromptProps {
  open: boolean;
  closeDialog: () => void;
}

function RAAMClinicReconnectionPrompt({
  open,
  closeDialog,
}: RAAMClinicReconnectionPromptProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const clinic: DFDClinicDataType | null =
    (JSON.parse(
      localStorage.getItem(LOCALSTORAGE_KEYS.previousClinic) as string
    ) as DFDClinicDataType) ?? null;

  const handleNo = useCallback(() => {
    closeDialog();

    navigate(
      `${ROUTE_URL.connect_now.index}/${ROUTE_URL.connect_now.initial_assessment}`
    );
  }, [closeDialog, navigate]);

  const handleYes = useCallback(() => {
    closeDialog();

    if (!clinic?.dfdLink) {
      console.error('No dfd website found');
      navigate(
        `${ROUTE_URL.connect_now.index}/${ROUTE_URL.connect_now.initial_assessment}`
      );
      return;
    }

    window.location.href = clinic.dfdLink + '?referrer=ofd&check_in=true';
  }, [clinic, closeDialog, navigate]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      TransitionComponent={Transition}
      aria-describedby="reconnect-clinic-description"
    >
      <StyledAppBar>
        <Toolbar>
          <StyledHeaderText>{t('visited_clinic.header')}</StyledHeaderText>
          <IconButton
            edge="end"
            color="primary"
            onClick={closeDialog}
            aria-label={t('visited_clinic.close_button.aria_label')}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </StyledAppBar>
      <StyledDialogContent>
        <DialogContentText textAlign="left" id="reconnect-clinic-description">
          {t('visited_clinic.body', { previousClinic: clinic.name } as any)}
        </DialogContentText>
      </StyledDialogContent>
      <DialogActions>
        <ButtonGroupContainer>
          <StyledButton variant="outlined" onClick={handleNo}>
            {t('connect_now.no_button.label')}
          </StyledButton>
          <StyledButton variant="contained" onClick={handleYes}>
            {t('connect_now.yes_button.label')}
          </StyledButton>
        </ButtonGroupContainer>
      </DialogActions>
    </Dialog>
  );
}

export default memo(RAAMClinicReconnectionPrompt);
