import {
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { memo, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_URL } from '../../constants/routes';
import { useFindClinicContext } from '../../hooks/useFindClinicContext';
import { I18nAccess, useTranslation } from '../../hooks/useKeyedTranslation';
import { isValidPostalCode } from '../../utils';
import GeolocationButton from '../GeolocationButton';
import PostalCodeTextField from '../PostalCodeTextField';

const FindClinicInput = () => {
  const { setCoordFromPostalCode } = useFindClinicContext();
  const navigate = useNavigate();
  const [postalCode, setPostalCode] = useState('');
  const { t } = useTranslation();
  const [postalCodeError, setPostalCodeError] = useState<I18nAccess>();
  const shouldNavigate = useRef(true);

  const handleSubmitPostalCode = useCallback(async () => {
    setCoordFromPostalCode(postalCode).then(
      () => {
        if (shouldNavigate.current) {
          shouldNavigate.current = false;
          navigate(
            `${ROUTE_URL.find_clinic.results}?postalCode=${postalCode}`,
            {
              replace: false,
            }
          );
        }
      },
      (err: Error) => setPostalCodeError(err.message as I18nAccess)
    );
  }, [navigate, postalCode, setCoordFromPostalCode]);

  const geolocationSuccess = useCallback(
    (coords: google.maps.LatLngLiteral) => {
      if (shouldNavigate.current) {
        shouldNavigate.current = false;
        navigate(
          `${ROUTE_URL.find_clinic.results}?lat=${coords.lat}&lng=${coords.lng}`
        );
      }
    },
    [navigate]
  );

  return (
    <Stack alignItems="center" height="100%" padding={0}>
      <Grid
        direction="row"
        container
        maxWidth={{
          xs: '536px',
        }}
        height="min-content"
        rowSpacing={{ xs: 2 }}
        padding={{ xs: 2.5 }}
        columnSpacing={{ xs: 0, sm: 2 }}
        sx={{
          margin: '0 !important',
          width: '100%',
          borderRadius: '12px',
          background: 'inherit',
          backdropFilter: 'blur(6.7957px)',
          bgcolor: '#FFFFFFA6',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Grid item xs={12}>
          <Typography
            className="find-a-clinic--header"
            variant="h2"
            textAlign="center"
          >
            {t('find_clinic.input_location.header')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className="find-a-clinic--body">
            {t('find_clinic.input_location.body')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <FormControl id="find-a-clinic-postal-code" fullWidth>
              <PostalCodeTextField
                postalCode={postalCode}
                setPostalCode={setPostalCode}
                handleSubmit={handleSubmitPostalCode}
                otherErrorText={
                  postalCodeError ? t(postalCodeError) : undefined
                }
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          display="flex"
          justifyContent={{
            xs: 'space-around',
            sm: 'center',
          }}
          gap={{
            xs: 2,
            sm: 3,
          }}
          flexWrap="wrap"
        >
          <Button
            id="find-a-clinic-continue-btn"
            type="submit"
            aria-label={t('find_clinic.input_location.button_aria_label')}
            variant="contained"
            disabled={!isValidPostalCode(postalCode)}
            onClick={handleSubmitPostalCode}
          >
            {t('find_clinic.input_location.next_step')}
          </Button>
          <GeolocationButton
            onSuccess={geolocationSuccess}
            executeCallback={shouldNavigate}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default memo(FindClinicInput);
