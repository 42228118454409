import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import FindClinicContextProvider from '../contexts/FindClinicContext/FindClinicContextProvider';

export default function FindClinic(): JSX.Element {
  return (
    <FindClinicContextProvider>
      <Stack alignItems="center">
        <Outlet />
      </Stack>
    </FindClinicContextProvider>
  );
}
