import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

export const StyledCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  fontStyle: 'normal',
  textDecoration: 'underline',
  color: theme.palette.primary.main,
}));
