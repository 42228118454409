import { FilterList } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { DEEP_BLUE } from '../../../constants/colors';
import { useTranslation } from '../../../hooks/useKeyedTranslation';
import PopoverButton from '../PopoverButton';
import FilterMenuSwitch from './FilterMenuSwitch';
import MaxDistanceSlider from './MaxDistanceSlider';

interface FilterButtonProps {
  onMaxDistanceSliderChange?: (distance: number) => void;
}

/** Filter menu, to be displayed in popover. */
const FindClinicFilterMenu = memo(
  ({ onMaxDistanceSliderChange }: FilterButtonProps) => {
    const { t } = useTranslation();

    return (
      <>
        <Typography sx={{ color: DEEP_BLUE, fontSize: '14px' }}>
          {t('find_clinic.button.filter.distance')}
        </Typography>
        <Box sx={{ paddingLeft: '8px', marginBottom: '16px' }}>
          <MaxDistanceSlider onChange={onMaxDistanceSliderChange} />
        </Box>
        <FilterMenuSwitch filterMenuKey="acceptsVirtual" />
        <FilterMenuSwitch filterMenuKey="isOpen" />
      </>
    );
  }
);
FindClinicFilterMenu.displayName = 'FindClinicFilterMenu';

/** Filter button with menu in popover. */
const FindClinicFilterButton = ({
  onMaxDistanceSliderChange,
}: FilterButtonProps) => {
  const { t } = useTranslation();

  return (
    <PopoverButton
      label={t('find_clinic.button.filter.label')}
      icon={FilterList}
    >
      <FindClinicFilterMenu
        onMaxDistanceSliderChange={onMaxDistanceSliderChange}
      />
    </PopoverButton>
  );
};

export default memo(FindClinicFilterButton);
