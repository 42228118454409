import { AppBar, Box, Toolbar, styled, useScrollTrigger } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { memo, useLayoutEffect, useRef, useState } from 'react';
import DFDLogo from '../assets/1FD_logo.png';
import inQLogo from '../assets/inQ_logo.png';
import { useTranslation } from '../hooks/useKeyedTranslation';
import LanguageToggle from './LanguageToggle';
import ResponsiveImage from './ResponsiveImage';

const FlexGrowContainer = styled(Box)(() => ({
  flexGrow: 1,
}));

const FlexContainer = styled(Box)(() => ({
  display: 'flex',
}));

const FlexAlignCenterContainer = styled(FlexContainer)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  justifyContent: 'space-between',
  alignItems: 'center',
}));

interface StyledAppBarProps {
  trigger: 'true' | 'false';
}
const StyledAppBar = styled(AppBar)<StyledAppBarProps>(
  ({ theme, trigger }) => ({
    backgroundColor:
      trigger === 'true' ? theme.palette.background.paper : 'transparent',
    boxShadow: trigger === 'true' ? theme.shadows[12] : 'none',
    transition: theme.transitions.create(['box-shadow'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
    right: 'calc(100% - 100vw)',
    width: '100vw',
  })
);

const Header = memo(() => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<null | number>(null);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    setHeaderHeight(ref.current.clientHeight);
  }, []);

  return (
    <FlexGrowContainer
      {...(headerHeight !== null && {
        height: headerHeight,
        maxHeight: headerHeight,
      })}
    >
      <StyledAppBar
        position="fixed"
        ref={ref}
        trigger={trigger.toString() as 'true' | 'false'}
      >
        <StyledToolbar>
          <FlexContainer>
            <nav>
              <NavLink to="/" role="navigation" aria-label={t('homepage')}>
                <ResponsiveImage
                  src={DFDLogo}
                  objectFit="contain"
                  alt={`${t('header.title')} ${t('homepage')}`}
                  height={56}
                  width="auto"
                />
              </NavLink>
            </nav>
          </FlexContainer>

          <FlexAlignCenterContainer>
            <LanguageToggle />
            <ResponsiveImage
              src={inQLogo}
              objectFit="contain"
              alt="inQ logo" // en and fr are the same
              height={32}
              width="auto"
            />
          </FlexAlignCenterContainer>
        </StyledToolbar>
      </StyledAppBar>
    </FlexGrowContainer>
  );
});
Header.displayName = 'Header';

export default Header;
