import { Box } from '@mui/material';
import { memo } from 'react';
import { ModalContent as ModalContentType } from '../../../constants/ResourceSupport';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { keyGenerator } from '../../../utils';
import YouTubeVideo from '../../YoutubeVideo';
import { ModalContentGrid } from './ModalContentGrid';
import { Section } from './Section';

interface ModalContentProps {
  content: ModalContentType[];
}

export const ModalContent = memo<ModalContentProps>(({ content }) => {
  const isMobile = useIsMobile();
  const shouldRenderGrid = content.length > 3 && !isMobile;

  return (
    <Box display="flex" flexDirection="column">
      {shouldRenderGrid ? (
        <ModalContentGrid>
          {content.map((section, index) => {
            return (
              <Section
                key={keyGenerator(section.title, index)}
                section={section}
              />
            );
          })}
        </ModalContentGrid>
      ) : (
        <Box display="flex" flexDirection="column">
          {content.map((section, index) => {
            if (section.videoLink) {
              return (
                <YouTubeVideo
                  key={keyGenerator(section.title, index)}
                  link={section.videoLink}
                />
              );
            }

            return (
              <Section
                key={keyGenerator(section.title, index)}
                section={section}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
});

ModalContent.displayName = 'ModalContent';
