import { Typography, TypographyProps } from '@mui/material';
import _fp from 'lodash/fp';
import { memo, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { DEEP_BLUE } from '../../constants/colors';
import { useClinicFilter } from '../../hooks/useClinicFilter';
import { useClinicFilterContext } from '../../hooks/useClinicFilterContext';
import { useFindClinicContext } from '../../hooks/useFindClinicContext';
import { useTranslation } from '../../hooks/useKeyedTranslation';

export const FindClinicDescription = (props: Partial<TypographyProps>) => {
  const { t } = useTranslation();
  const { clinicData } = useFindClinicContext();
  const clinicFilter = useClinicFilter();
  const { dirty } = useClinicFilterContext();

  // Index specifically not passed to filter to avoid counting currently selected clinic, if outside normal filter
  const count = useMemo(
    () =>
      _fp.flow([
        // countBy counts how many times the iteratee returns each value.
        // As this iteratee returns a boolean, countBy returns a dictionary with the keys `true` and `false`
        // containing the number of times each value was returned.
        _fp.countBy(clinicFilter),
        // The number of times the clinic filter returned true == the number of valid clinics
        _fp.get('true'),
      ])(clinicData) ?? 0,
    [clinicData, clinicFilter]
  );

  let description = t('find_clinic.description.based');
  if (dirty) {
    description += ' ' + t('find_clinic.description.filter');
  }
  description += ', ';
  let countString: JSX.Element = <></>;
  switch (count) {
    case 0:
      countString = <Trans i18nKey={'find_clinic.description.zero'} />;
      break;
    case 1:
      countString = <Trans i18nKey={'find_clinic.description.one'} />;
      break;
    default:
      countString = (
        <Trans i18nKey={'find_clinic.description.many'} values={{ count }} />
      );
  }

  return (
    <Typography
      component="span"
      fontWeight="400"
      fontSize="0.9em"
      lineHeight="19px"
      color={DEEP_BLUE}
      align="left"
      {...props}
    >
      {description}
      {countString}
    </Typography>
  );
};

export default memo(FindClinicDescription);
