import { MyLocation } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { memo, useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DEEP_BLUE } from '../../../constants/colors';
import { useClinicFilterContext } from '../../../hooks/useClinicFilterContext';
import { useFindClinicContext } from '../../../hooks/useFindClinicContext';
import { I18nAccess, useTranslation } from '../../../hooks/useKeyedTranslation';
import { usePopoverContext } from '../../../hooks/usePopoverContext';
import { clearLocationParams, isValidPostalCode } from '../../../utils';
import GeolocationButton from '../../GeolocationButton';
import PostalCodeTextField from '../../PostalCodeTextField';
import PopoverButton from '../PopoverButton';

const RefineLocationMenu = memo(() => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [postalCode, setPostalCode] = useState(
    searchParams.get('postalCode') ?? ''
  );
  const { setCoordFromPostalCode } = useFindClinicContext();
  const { closePopover } = usePopoverContext();
  const { updateClinicFilter } = useClinicFilterContext();
  const [postalCodeError, setPostalCodeError] = useState<I18nAccess>();

  const handleSubmit = useCallback(() => {
    setCoordFromPostalCode(postalCode).then(
      () => {
        setSearchParams(
          (prev) => {
            return {
              ...clearLocationParams(prev),
              postalCode,
            };
          },
          { replace: true }
        );
        updateClinicFilter({ type: 'reset' });
        closePopover();
      },
      (err: Error) => setPostalCodeError(err.message as I18nAccess)
    );
  }, [
    setSearchParams,
    postalCode,
    setCoordFromPostalCode,
    updateClinicFilter,
    closePopover,
  ]);

  const disabled = useMemo(() => !isValidPostalCode(postalCode), [postalCode]);

  return (
    <>
      <Typography
        align="center"
        marginY="12px"
        color={DEEP_BLUE}
        fontSize="24px"
      >
        {t('find_clinic.button.location.label')}
      </Typography>
      <Typography
        component="span"
        fontWeight="400"
        fontSize="0.9em"
        lineHeight="19px"
        align="left"
      >
        {t('find_clinic.button.location.description')}
      </Typography>
      <PostalCodeTextField
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        handleSubmit={handleSubmit}
        showSubmitArrow
        smallPlaceholder
        boxProps={{ paddingY: '12px' }}
        formHelperTextProps={{ sx: { lineHeight: '14px' } }}
        outlinedInputProps={{ size: 'small' }}
        otherErrorText={postalCodeError ? t(postalCodeError) : undefined}
      />
      <Box width="100%" display="flex" justifyContent="center">
        <GeolocationButton onSuccess={closePopover} />
      </Box>
      <Grid container marginTop="16px">
        <Grid
          item
          xs={6}
          sx={{ display: 'inline-flex', justifyContent: 'center' }}
        >
          <Button variant="outlined" onClick={closePopover}>
            {t('find_clinic.button.location.cancel')}
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ display: 'inline-flex', justifyContent: 'center' }}
        >
          <Button
            variant="contained"
            disabled={disabled}
            onClick={handleSubmit}
          >
            {t('find_clinic.button.location.continue')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
});
RefineLocationMenu.displayName = 'RefineLocationMenu';

const RefineLocationButton = () => {
  const { t } = useTranslation();

  return (
    <PopoverButton
      label={t('find_clinic.button.location.label')}
      icon={MyLocation}
    >
      <RefineLocationMenu />
    </PopoverButton>
  );
};

export default memo(RefineLocationButton);
