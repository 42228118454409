import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import {
  InnerAccordionSection,
  isCardInnerAccordionSection,
  isClinicInnerAccordionSection,
} from '../../../constants/ResourceSupport';
import { useTranslation } from '../../../hooks/useKeyedTranslation';
import ResourceCards from '../ResourceCards/ResourceCards';
import { StyledCardText } from '../ResourceCards/StyledCardText';
import { StyledSubContentTypography } from '../StyledSubContentTypography';
import { ClinicLocationCard } from './ClinicLocationCard';
import InnerAccordion from './InnerAccordion';

interface ContentInnerAccordionProps {
  section: InnerAccordionSection;
}

export const ContentInnerAccordion = ({
  section,
}: ContentInnerAccordionProps) => {
  const { t } = useTranslation();

  let children: ReactNode;
  let title: string;

  if (isClinicInnerAccordionSection(section)) {
    children = (
      <>
        <StyledCardText>{t('lorem.long')}</StyledCardText>
        <Grid container spacing={2} paddingTop="1rem" alignContent="stretch">
          {section.content.locations.map((location, index) => (
            <ClinicLocationCard
              clinic={section.content}
              location={location}
              key={index}
            />
          ))}
        </Grid>
      </>
    );
    title = section.title;
  } else if (isCardInnerAccordionSection(section)) {
    children = <ResourceCards resources={section.content} />;
    title = t(section.title);
  } else {
    children = (
      <StyledSubContentTypography>
        {t(section.content)}
      </StyledSubContentTypography>
    );
    title = t(section.title);
  }

  return (
    <InnerAccordion summaryText={title} fullWidth={true}>
      {children}
    </InnerAccordion>
  );
};
