import { Accordion, AccordionProps, styled } from '@mui/material';

interface StyledInnerAccordionProps extends AccordionProps {
  fullWidth?: boolean;
}

export const StyledInnerAccordion = styled(
  (props: StyledInnerAccordionProps) => (
    <Accordion elevation={0} disableGutters square {...props} />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'fullWidth',
  }
)(({ fullWidth, theme }) => ({
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  '&.MuiAccordion-root:before': {
    opacity: 0,
  },
  '&:first-of-type': {
    borderRadius: '0.5rem 0.5rem 0 0',
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:last-child': {
    borderRadius: '0 0 0.5rem 0.5rem',
  },
  '&:only-child': {
    borderRadius: '0.5rem',
  },
  maxWidth: fullWidth ? '100%' : '50rem',
  marginRight: 'auto',
  marginLeft: 'auto',
}));
