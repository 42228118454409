import _ from 'lodash';
import { useMemo } from 'react';
import { isDFDClinic, round } from '../utils';
import { useFindClinicContext } from './useFindClinicContext';

// The distance slider will have a maximum value that shows at least this many clinics.
// Because the maximum distance of the slider is rounded up to a prettier number, more clinics may be shown.
export const MIN_CLINICS_DISPLAYABLE = 20;

interface SliderRange {
  sliderMax: number;
  sliderMin: number;
}

export const useClinicDistanceSliderRange = (): SliderRange => {
  const { clinicData, getClinicDistance } = useFindClinicContext();

  const firstDFDIndex = _.findIndex(clinicData, isDFDClinic);

  // Round to just above the distance to the ~20th closest clinic
  const sliderMax = useMemo(
    () =>
      round(
        getClinicDistance(
          clinicData[
            _.clamp(
              clinicData.length - 1,
              0,
              Math.max(firstDFDIndex, MIN_CLINICS_DISPLAYABLE)
            )
          ]
        )
      ),
    [clinicData, firstDFDIndex, getClinicDistance]
  );

  // Round to just below the distance to the second closest clinic, but ensure that the closest clinic is always shown
  const sliderMin = useMemo(
    () =>
      Math.max(
        round(getClinicDistance(clinicData[1]), undefined, Math.floor),
        round(getClinicDistance(clinicData[0]))
      ),
    [getClinicDistance, clinicData]
  );

  return useMemo(() => ({ sliderMin, sliderMax }), [sliderMin, sliderMax]);
};
