import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  FormHelperText,
  FormHelperTextProps,
  IconButton,
  OutlinedInput,
  OutlinedInputProps,
  useFormControl,
} from '@mui/material';
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  KeyboardEvent,
  Ref,
  SetStateAction,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IMaskInput } from 'react-imask';
import { useTranslation } from '../hooks/useKeyedTranslation';
import { isValidPostalCode } from '../utils';
interface SubmitArrowProps {
  disabled?: boolean;
  onClick?: () => void;
}

const SubmitArrow = memo(({ disabled, onClick }: SubmitArrowProps) => {
  return (
    <IconButton disabled={disabled} onClick={onClick}>
      <ArrowForward color={disabled ? 'disabled' : 'primary'} />
    </IconButton>
  );
});
SubmitArrow.displayName = 'SubmitArrow';

interface PostalCodeHelperTextProps {
  errorText: string;
  error: boolean;
  helperText: string;
  formHelperTextProps?: Partial<FormHelperTextProps>;
  otherErrorText?: string;
}

function PostalCodeHelperText({
  error,
  errorText,
  helperText,
  formHelperTextProps,
  otherErrorText,
}: PostalCodeHelperTextProps) {
  const { focused } = useFormControl() || {};

  const helperTextMemo = useMemo(() => {
    if (otherErrorText) {
      return otherErrorText;
    }

    if (focused && error) {
      return errorText;
    }

    return helperText;
  }, [otherErrorText, focused, error, helperText, errorText]);

  // Split sx from props to ensure it doesn't automatically overwrite default sx
  const { sx: formHelperTextSx = undefined, ...otherFormHelperTextProps } =
    formHelperTextProps ?? {};

  return (
    <FormHelperText
      sx={{
        fontStyle: 'italic',
        textAlign: 'left',
        marginX: 1,
        ...formHelperTextSx,
      }}
      {...otherFormHelperTextProps}
    >
      {helperTextMemo}
    </FormHelperText>
  );
}

interface PostalCodeTextFieldProps {
  postalCode: string;
  setPostalCode:
    | Dispatch<SetStateAction<string>>
    | ((postalCode: string) => void);

  /** Code to be run when enter is pressed and the field contains a valid postal code. */
  handleSubmit?: () => void;
  /** Whether to show a clickable arrow that calls `handleSubmit`. */
  showSubmitArrow?: boolean;

  smallPlaceholder?: boolean;

  /** Extra props for the Box that contains the `OutlinedInput` and `FormHelperText`. */
  boxProps?: Partial<BoxProps>;
  /** Extra props for the `OutlinedInput` that the postal code is entered into. */
  outlinedInputProps?: Partial<OutlinedInputProps>;
  /** Extra props for the `FormHelperText` providing feedback for the postal code input. */
  formHelperTextProps?: Partial<FormHelperTextProps>;

  otherErrorText?: string;
}

interface PostalCodeMaskCustomProps {
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const PostalCodeMaskCustom = forwardRef(
  (props: PostalCodeMaskCustomProps, ref: Ref<HTMLInputElement>) => {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        onAccept={(value) => onChange?.({ target: { value } } as any)}
        // Give some leeway to edit extra characters, e.g if a full address is pasted in
        mask="*** ******************************************"
        inputRef={ref}
      />
    );
  }
);

const PostalCodeTextField = ({
  postalCode,
  setPostalCode,
  handleSubmit,
  showSubmitArrow,
  smallPlaceholder,
  boxProps,
  outlinedInputProps,
  formHelperTextProps,
  otherErrorText: otherErrorTextProp,
}: PostalCodeTextFieldProps) => {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);

  const [otherErrorText, setOtherErrorText] = useState(otherErrorTextProp);
  useEffect(() => setOtherErrorText(otherErrorTextProp), [otherErrorTextProp]);

  const handleChangePostalCode = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setOtherErrorText(undefined);

      const inputPostalCode = e.target.value;

      // Postal code saved without a space in the middle, a space is only added when displayed to the user
      const formattedPostalCode = inputPostalCode
        .replace(/\s/g, '')
        .toUpperCase();

      setPostalCode(formattedPostalCode);
    },
    [setPostalCode]
  );

  const isPostalCodeValid = useMemo(
    () => isValidPostalCode(postalCode),
    [postalCode]
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') {
        return;
      }

      if (!isPostalCodeValid) {
        return;
      }

      handleSubmit?.();
    },
    [handleSubmit, isPostalCodeValid]
  );

  // Split sx from props to ensure it doesn't automatically overwrite default sx
  const { sx: outlinedInputSx = undefined, ...otherOutlinedInputProps } =
    outlinedInputProps ?? {};

  return (
    <Box {...boxProps}>
      <OutlinedInput
        placeholder={t(
          smallPlaceholder
            ? 'find_clinic.input_location.placeholder_small'
            : 'find_clinic.input_location.placeholder'
        )}
        value={postalCode}
        fullWidth
        onChange={handleChangePostalCode}
        onKeyDown={handleKeyPress}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        error={!!otherErrorText || (!isPostalCodeValid && focused)}
        endAdornment={
          showSubmitArrow && (
            <SubmitArrow disabled={!isPostalCodeValid} onClick={handleSubmit} />
          )
        }
        sx={{
          paddingRight: 0,
          ...outlinedInputSx,
        }}
        inputComponent={PostalCodeMaskCustom}
        {...otherOutlinedInputProps}
      />
      <PostalCodeHelperText
        helperText={t('find_clinic.input_location.helper_text')}
        errorText={t('find_clinic.input_location.error_text')}
        error={!isPostalCodeValid}
        formHelperTextProps={formHelperTextProps}
        otherErrorText={otherErrorText}
      />
    </Box>
  );
};

export default memo(PostalCodeTextField);
