import { Dispatch, SetStateAction, createContext } from 'react';
import { ClinicDataType } from '../../utils';

export interface FindClinicContextType {
  coordinates: google.maps.LatLng | google.maps.LatLngLiteral | null;
  setCoordinates: Dispatch<
    google.maps.LatLng | google.maps.LatLngLiteral | null
  >;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setCoordFromPostalCode: (postalCode: string) => Promise<void>;
  /** All available clinics */
  clinicData: ClinicDataType[];
  /** Helper function that returns the distance from the user to a clinic. */
  getClinicDistance: (clinic?: ClinicDataType) => number;
}

export const FindClinicContext = createContext<FindClinicContextType>({
  coordinates: null,
  setCoordinates: () => {},
  isLoading: false,
  setIsLoading: () => {},
  setCoordFromPostalCode: async () => {},
  clinicData: [],
  getClinicDistance: () => 0,
});

export default FindClinicContext;
