import { Stack, StackProps, styled } from '@mui/material';

export const StyledStack = styled((props: StackProps) => (
  <Stack spacing={2} {...props} />
))(({ theme }) => ({
  alignItems: 'start',
  background: 'rgba(255, 255, 255, 0.8)',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '16px',
  padding: '1.5em',
  gap: theme.spacing(2),
}));
