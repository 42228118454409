import { Box, Slider, Typography } from '@mui/material';
import _ from 'lodash';
import { SyntheticEvent, memo, useCallback, useEffect } from 'react';
import { DEEP_BLUE } from '../../../constants/colors';
import { DEFAULT_MAX_DISTANCE } from '../../../contexts/ClinicFilterContext';
import { useClinicDistanceSliderRange } from '../../../hooks/useClinicDistanceSliderRange';
import { useClinicFilterContext } from '../../../hooks/useClinicFilterContext';

interface MaxDistanceSliderProps {
  onChange?: (distance: number) => void;
}

const MaxDistanceSlider = ({ onChange }: MaxDistanceSliderProps) => {
  const { maxDistance, updateClinicFilter } = useClinicFilterContext();
  const { sliderMin, sliderMax } = useClinicDistanceSliderRange();

  // Run on every slider value change
  const handleChange = useCallback(
    (_event: SyntheticEvent | Event, newValue: number | number[]) => {
      if (typeof newValue === 'number') {
        // As our slider is not a range, newValue should never be a `number[]`
        // and this code should always be run
        updateClinicFilter({ type: 'maxDistance', value: newValue });
        onChange?.(newValue);
      }
    },
    [updateClinicFilter, onChange]
  );

  useEffect(() => {
    if (maxDistance < sliderMin) {
      updateClinicFilter({
        type: 'maxDistance',
        value: sliderMin,
        clean: true,
      });
    } else if (maxDistance > sliderMax && sliderMax !== 0) {
      if (isFinite(maxDistance)) {
        updateClinicFilter({
          type: 'maxDistance',
          value: sliderMax,
          clean: true,
        });
      } else {
        updateClinicFilter({
          type: 'maxDistance',
          value: _.clamp(DEFAULT_MAX_DISTANCE, sliderMin, sliderMax),
          clean: true,
        });
      }
    }
  }, [maxDistance, sliderMin, sliderMax, updateClinicFilter]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Slider
        track={false}
        sx={{ marginRight: '10px' }}
        size="small"
        onChange={handleChange}
        min={sliderMin}
        max={sliderMax}
        step={0.25}
        value={maxDistance}
      />
      <Typography
        sx={{
          color: DEEP_BLUE,
          fontWeight: 600,
          flexBasis: '65px',
        }}
      >
        {/* `maxDistance` is initially infinite before any filters are selected. */}
        {isFinite(maxDistance) ? Math.round(maxDistance) : sliderMax}km
      </Typography>
    </Box>
  );
};

export default memo(MaxDistanceSlider);
