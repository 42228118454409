import { Link } from '@mui/material';
import { ReactNode, memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card } from '../../../constants/ResourceSupport';
import { useTranslation } from '../../../hooks/useKeyedTranslation';
import ResourceIcon from './ResourceIcon';
import { ResourceLinkText } from './ResourceIconProps';
import { StyledCard } from './StyledCard';
import { StyledCardActions } from './StyledCardActions';
import { StyledCardContent } from './StyledCardContent';
import { StyledCardHeader } from './StyledCardHeader';
import { StyledCardText } from './StyledCardText';
import { StyledCardTitle } from './StyledCardTitle';

interface ResourceCardProps {
  resource: Card;
  onClickCardHandler?: () => void;
  children?: ReactNode;
}

const ResourceCard = memo(({ resource, children }: ResourceCardProps) => {
  const { t } = useTranslation();
  const { id: resourceId, type, title: resourceTitle } = resource;
  const contentId = `resource-card-content-${resourceTitle}-${resourceId}`;
  const cardDescriptionId = `resource-card-description-${resourceTitle}-${resourceId}`;
  const resourceLinkText = ResourceLinkText({ type });

  return (
    <StyledCard>
      <StyledCardHeader
        action={
          <Link
            component={RouterLink}
            to={resourceId}
            state={{
              resource: resource,
            }}
          >
            <ResourceIcon type={type} />
          </Link>
        }
        title={
          <StyledCardTitle variant="h6">{t(resourceTitle)}</StyledCardTitle>
        }
      />

      <StyledCardContent id={contentId} aria-describedby={cardDescriptionId}>
        <StyledCardText
          id={cardDescriptionId}
          variant="body2"
          color="text.secondary"
        >
          {children}
        </StyledCardText>
      </StyledCardContent>
      <StyledCardActions>
        <Link
          component={RouterLink}
          to={resourceId}
          state={{
            resource: resource,
          }}
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {t(resourceLinkText)}
        </Link>
      </StyledCardActions>
    </StyledCard>
  );
});

ResourceCard.displayName = 'ResourceCard';

export default ResourceCard;
