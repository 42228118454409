import { Marker } from '@react-google-maps/api';
import { memo } from 'react';
import mapMarkerUser from '../../../assets/map-marker-user.svg';

interface UserMarkerProps {
  position: google.maps.LatLng | google.maps.LatLngLiteral | null;
}

const UserMarker = memo(({ position }: UserMarkerProps) => {
  if (!position) {
    return null;
  }

  return (
    <Marker
      key={'User Location'}
      position={position}
      icon={{
        url: mapMarkerUser,
        anchor: new window.google.maps.Point(15, 15),
      }}
      clickable={false}
    />
  );
});

export default UserMarker;
