import { Button, ButtonProps, styled } from '@mui/material';
import { useTranslation } from '../../hooks/useKeyedTranslation';

const StyledCloseButton = styled((props: ButtonProps) => (
  <Button variant="outlined" {...props} />
))(({ theme }) => ({
  borderRadius: '0.25rem',
  padding: '0.375rem 1rem',
  justifyContent: 'center',
  alignItems: 'center',
  border: `2px solid ${theme.palette.primary.main}`,

  ':hover': {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

interface CloseButtonProps extends ButtonProps {
  handleClose: () => void;
}

export function CloseButton({ handleClose, ...rest }: CloseButtonProps) {
  const { t } = useTranslation();

  return (
    <StyledCloseButton
      id="close-button"
      onClick={handleClose}
      aria-label={t('common.button.close.aria_label')}
      title={t('common.button.back.title')}
      {...rest}
    >
      {t('common.button.close.label')}
    </StyledCloseButton>
  );
}
