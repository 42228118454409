export const DARK_BLUE = '#02253C';
export const DEEP_BLUE = '#26436E';
export const MEDIUM_BLUE = '#006BBD';
export const BORDER_BLUE = '#143862';
export const VIBRANT_TANGERINE = '#FF832A';
export const BORDER_TANGERINE = '#7F4922';
export const PALE_GRAY = '#91A0B6';
export const DARK_GRAY = '#BFBFBF';
export const MEDIUM_GRAY = '#888888';
export const BRIGHT_GREEN = '#06A933';
export const MEDIUM_GREEN = '#006D21';
export const ERROR = '#D32F2F';
export const SHADOW_GREY = 'rgba(209, 220, 243, 0.5)';
export const WHITE = '#FFFFFF';
