export const keyGenerator = (...params: (string | number)[]): string => {
  if (params.length === 0) {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  }
  return params.map((param) => String(param)).join('-');
};

export default keyGenerator;
