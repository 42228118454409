import { Accordion, styled } from '@mui/material';
import { memo } from 'react';

export const StyledAccordion = memo(
  styled(Accordion)(() => ({
    '&.MuiAccordion-root:first-of-type': {
      borderRadius: '8px 8px 0 0' /* Adjust the border radius as needed */,
    },

    '&.MuiAccordion-root:last-child': {
      borderRadius: '0 0 8px 8px' /* Adjust the border radius as needed */,
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
    '&.Mui-expanded::before': {
      opacity: 1,
    },
  }))
);
