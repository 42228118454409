import { CircularProgress, Stack } from '@mui/material';

export default function Connecting(): JSX.Element {
  return (
    <Stack
      flexGrow="1"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress />
    </Stack>
  );
}
