import { Box, Skeleton, styled } from '@mui/material';
import { FC, memo, useCallback, useState } from 'react';

interface ImageProps {
  src: string;
  alt: string;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  width?: string | number;
  height?: string | number;
  borderRadius?: boolean;
}

const ResponsiveImageContainer = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
}));

const ResponsiveImage: FC<ImageProps> = ({
  src,
  alt,
  objectFit = 'cover',
  width = '100%',
  height = '100%',
  borderRadius = false,
}) => {
  const [imgLoaded, setImgLoaded] = useState(false);

  const onLoadHandler = useCallback(() => setImgLoaded(true), []);

  return (
    <ResponsiveImageContainer width={width} height={height}>
      {!imgLoaded && (
        <Skeleton variant="rectangular" height={height} width={width} />
      )}
      <Box
        component="img"
        src={src}
        alt={alt}
        loading="lazy"
        height={height}
        width={width}
        sx={{
          objectFit,
          opacity: imgLoaded ? 1 : 0,
          transition: 'opacity 0.5s',
          maxWidth: '100%',
          maxHeight: '100%',
          ...(borderRadius && {
            borderRadius: '1rem',
          }),
        }}
        onLoad={onLoadHandler}
      />
    </ResponsiveImageContainer>
  );
};

ResponsiveImage.displayName = 'ResponsiveImage';

export default memo(ResponsiveImage);
