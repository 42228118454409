import { Box, Switch, Typography } from '@mui/material';
import { ChangeEvent, memo, useCallback } from 'react';
import { DEEP_BLUE } from '../../../constants/colors';
import { useClinicFilterContext } from '../../../hooks/useClinicFilterContext';
import { useTranslation } from '../../../hooks/useKeyedTranslation';

interface FilterMenuSwitchProps extends FilterMenuSwitchManagerProps {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const FilterMenuSwitch = memo(
  ({ filterMenuKey, checked, onChange }: FilterMenuSwitchProps) => {
    const { t } = useTranslation();

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '4px',
        }}
      >
        <Typography
          sx={{
            color: DEEP_BLUE,
            fontSize: '14px',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          {t(`find_clinic.button.filter.switches.${filterMenuKey}`)}
        </Typography>
        <Switch size="small" checked={checked} onChange={onChange} />
      </Box>
    );
  }
);
FilterMenuSwitch.displayName = 'FilterMenuSwitch';

interface FilterMenuSwitchManagerProps {
  /** The key of the filter attribute this switch controls. */
  filterMenuKey: 'acceptsVirtual' | 'isOpen';
}

/** A switch that controls an attribute of the filter menu.
 * Label is automatically filled from translation file in based on key.
 */
const FilterMenuSwitchManager = ({
  filterMenuKey,
}: FilterMenuSwitchManagerProps) => {
  const { [filterMenuKey]: value, updateClinicFilter } =
    useClinicFilterContext();

  const handleChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      updateClinicFilter({ type: filterMenuKey, value: checked });
    },
    [updateClinicFilter, filterMenuKey]
  );

  return (
    <FilterMenuSwitch
      filterMenuKey={filterMenuKey}
      checked={value}
      onChange={handleChange}
    />
  );
};

export default memo(FilterMenuSwitchManager);
