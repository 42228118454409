import { memo } from 'react';
import { Outlet } from 'react-router-dom';

const ConnectNowLayout = memo(() => {
  return <Outlet />;
});

ConnectNowLayout.displayName = 'ConnectNowLayout';

export default ConnectNowLayout;
