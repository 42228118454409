import { Typography, styled } from '@mui/material';

export const ContentTitle = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  fontSize: '1.375rem',
  fontWeight: 500,
  lineHeight: '1.625rem',
  color: theme.palette.primary.main,
  maxWidth: '30ch',
}));
