import { I18nAccess } from '../hooks/useKeyedTranslation';

export function getGeolocationErrorReason(
  geolocationPermission?: PermissionState,
  geolocationError?: GeolocationPositionError
) {
  let key: I18nAccess = 'find_clinic.input_location.info.available';
  if (geolocationPermission === 'denied') {
    key = 'find_clinic.input_location.info.denied';
  }
  if (geolocationError) {
    switch (geolocationError.code) {
      case geolocationError.PERMISSION_DENIED:
        key = 'find_clinic.input_location.info.denied';
        break;
      case geolocationError.POSITION_UNAVAILABLE:
        // key = 'find_clinic.input_location.info.unavailable';
        // The above key is more correct in theory, but you get this error in MacOS when you
        // turn off Location Services so the below key is probably more accurate in practice
        key = 'find_clinic.input_location.info.denied';
        break;
      case geolocationError.TIMEOUT:
        key = 'find_clinic.input_location.info.timeout';
        break;
    }
  }
  if (!window.isSecureContext) {
    key = 'find_clinic.input_location.info.insecure';
  }
  return key;
}
