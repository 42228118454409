import {
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummaryProps,
} from '@mui/material';
import { ReactNode } from 'react';
import { StyledInnerAccordion } from './StyledInnerAccordion';
import { StyledInnerAccordionSummary } from './StyledInnerAccordionSummary';
import { StyledInnerAccordionTypography } from './StyledInnerAccordionTypography';

type CustomAccordionProps = Omit<AccordionProps, 'children'>;

interface InnerAccordionProps {
  summaryText: string;
  children?: ReactNode;
  innerAccordionProps?: CustomAccordionProps[keyof CustomAccordionProps];
  innerAccordionSummaryProps?: AccordionSummaryProps;
  innerAccordionDetailsProps?: AccordionDetailsProps;
  fullWidth?: boolean;
}

const InnerAccordion = ({
  summaryText,
  children,
  innerAccordionProps,
  innerAccordionSummaryProps,
  innerAccordionDetailsProps,
  fullWidth,
}: InnerAccordionProps) => {
  const accordionId = `inner-accordion-${summaryText
    .toLowerCase()
    .replace(/\s/g, '-')}`;

  return (
    <StyledInnerAccordion {...innerAccordionProps} fullWidth={fullWidth}>
      <StyledInnerAccordionSummary
        {...innerAccordionSummaryProps}
        aria-controls={accordionId + '-content'}
        id={accordionId + '-header'}
      >
        <StyledInnerAccordionTypography>
          {summaryText}
        </StyledInnerAccordionTypography>
      </StyledInnerAccordionSummary>
      <AccordionDetails {...innerAccordionDetailsProps}>
        {children}
      </AccordionDetails>
    </StyledInnerAccordion>
  );
};
InnerAccordion.displayName = 'InnerAccordion';

export default InnerAccordion;
