import { Typography } from '@mui/material';
import { memo } from 'react';

export interface ClinicDetailsProps {
  details: string;
}

export const ClinicTextDetails = memo(({ details }: ClinicDetailsProps) => {
  return (
    <Typography textAlign="left" fontStyle="normal" lineHeight="20px">
      {details}
    </Typography>
  );
});
ClinicTextDetails.displayName = 'ClinicTextDetails';

export default ClinicTextDetails;
