import { Link, Tooltip } from '@mui/material';
import { memo } from 'react';
import { MEDIUM_BLUE } from '../../../../constants/colors';
import {
  I18nAccess,
  useTranslation,
} from '../../../../hooks/useKeyedTranslation';
import { ClinicDetailsProps } from './ClinicTextDetails';

interface ClinicLinkDetailsProps extends ClinicDetailsProps {
  /** `details` will be used if url not provided. */
  href?: string;
  /** `...link_button` will be used if prop not provided. */
  tooltipI18n?: I18nAccess;
}

export const ClinicLinkDetails = memo(
  ({ details, href, tooltipI18n }: ClinicLinkDetailsProps) => {
    const { t } = useTranslation();

    return (
      <Tooltip
        title={t(tooltipI18n ?? 'find_clinic.tooltip.link_button')}
        disableInteractive
      >
        <Link
          href={href ?? details}
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
          sx={{
            color: MEDIUM_BLUE,
            fontWeight: '700',
            lineHeight: '20px',
            fontStyle: 'normal',
            overflowWrap: 'break-word',
          }}
        >
          {details}
        </Link>
      </Tooltip>
    );
  }
);
ClinicLinkDetails.displayName = 'ClinicLinkDetails';

export default ClinicLinkDetails;
