import { Typography, TypographyProps, styled } from '@mui/material';

export const StyledSubHeadingTypography = styled(
  (props: TypographyProps<'h3'>) => <Typography component="h3" {...props} />
)(({ theme }) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '1.25rem',
  color: theme.palette.primary.main,
}));
