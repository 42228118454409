import { Button, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import FindClinicImage from '../assets/RAAM_1FD_find_clinic_map.webp';
import LandingImage from '../assets/RAAM_Landing-330x230.webp';
import RAAMClinicReconnectionPrompt from '../components/Dialog/RAAMClinicReconnectionPrompt';
import {
  CenteredContainer,
  ConnectingCard,
  InformationLink,
  ItalicHeading,
  PageText,
  PageTitle,
  ResponsiveCardStack,
  ResponsiveRowContainer,
  ResponsiveStackContainer,
  StyledUL,
} from '../components/LandingPage';
import ResponsiveImage from '../components/ResponsiveImage';
import { ROUTE_URL } from '../constants/routes';
import { useConnectNowButtonHandlers } from '../hooks/useConnectNowButtonHandlers';
import { useTranslation } from '../hooks/useKeyedTranslation';
import { keyGenerator } from '../utils';

export default function LandingPage(): JSX.Element {
  const { t } = useTranslation();
  const [isModalOpen, connectNowHandler, closeModal] =
    useConnectNowButtonHandlers();

  return (
    <Stack spacing={2} my={2}>
      <ResponsiveCardStack gap={2} useFlexGap flexWrap="wrap">
        <PageTitle variant="h1" textAlign="left">
          {t('landing.title')}
        </PageTitle>

        <ResponsiveRowContainer>
          <Stack spacing={2}>
            <ItalicHeading variant="h2" textAlign="left">
              {t('landing.subheading')}
            </ItalicHeading>
            <PageText textAlign="left">{t('landing.body')}</PageText>
          </Stack>

          <CenteredContainer>
            <ResponsiveImage
              src={LandingImage}
              alt="landing image"
              width={330}
              height={230}
              objectFit="contain"
            />
          </CenteredContainer>
        </ResponsiveRowContainer>
      </ResponsiveCardStack>

      <ResponsiveCardStack>
        <Stack alignItems="center" spacing={2}>
          <ItalicHeading variant="h2" textAlign="center">
            {t('landing.cards.connect_now.header')}
          </ItalicHeading>

          <ResponsiveStackContainer gap={2} flexWrap="nowrap">
            {[
              {
                cardTitle: t('landing.cards.connect_now.subheading1'),
                cardText: t('landing.cards.connect_now.body1'),
              },
              {
                cardTitle: t('landing.cards.connect_now.subheading2'),
                cardText: t('landing.cards.connect_now.body2'),
              },
              {
                cardTitle: t('landing.cards.connect_now.subheading3'),
                cardText: t('landing.cards.connect_now.body3'),
              },
            ].map((card, index) => (
              <ConnectingCard
                key={keyGenerator(card.cardTitle, index)}
                circleText={(index + 1).toString()}
                cardTitle={card.cardTitle}
                cardText={card.cardText}
              />
            ))}
          </ResponsiveStackContainer>

          <CenteredContainer>
            <Button
              onClick={connectNowHandler}
              title={t('landing.cards.connect_now.button.title')}
              aria-label={t('landing.cards.connect_now.button.aria_label')}
              variant="contained"
            >
              {t('landing.cards.connect_now.button.label')}
            </Button>
          </CenteredContainer>
        </Stack>
      </ResponsiveCardStack>

      <ResponsiveRowContainer
        alignItems={{ xs: 'stretch', sm: 'flex-start', md: 'stretch' }}
      >
        <ResponsiveCardStack flex={`0 0 ${(1 / 3) * 100}%`}>
          <Stack spacing={2}>
            <ItalicHeading variant="h2" textAlign="left">
              {t('landing.cards.find_clinic.header')}
            </ItalicHeading>

            <PageText textAlign="left">
              {t('landing.cards.find_clinic.body')}
            </PageText>

            <CenteredContainer>
              <ResponsiveImage
                src={FindClinicImage}
                alt={t('landing.cards.find_clinic.image.alt')}
                width={368}
                height={191}
              />
            </CenteredContainer>

            <CenteredContainer>
              <Button
                component={RouterLink}
                to={ROUTE_URL.find_clinic.index}
                title={t('landing.cards.find_clinic.button.title')}
                aria-label={t('landing.cards.find_clinic.button.aria_label')}
                variant="contained"
              >
                {t('landing.cards.find_clinic.button.label')}
              </Button>
            </CenteredContainer>
          </Stack>
        </ResponsiveCardStack>
        <ResponsiveCardStack>
          <Stack spacing={2}>
            <ItalicHeading variant="h2" textAlign="left">
              {t('landing.cards.additional_resources.header')}
            </ItalicHeading>

            <PageText textAlign="left">
              {t('landing.cards.additional_resources.body')}{' '}
              <InformationLink href={ROUTE_URL.resources_support.index}>
                {t('landing.cards.additional_resources.link_button.label')}
              </InformationLink>
            </PageText>
            <PageText textAlign="left">
              <InformationLink
                href={
                  ROUTE_URL.resources_support.index +
                  ROUTE_URL.resources_support.raam_clinic
                }
              >
                {t(
                  'landing.cards.additional_resources.raam_clinic_network.link_button.label'
                )}
              </InformationLink>{' '}
              {t('landing.cards.additional_resources.raam_clinic_network.body')}
            </PageText>
            <PageText textAlign="left">
              <InformationLink
                href={
                  ROUTE_URL.resources_support.index +
                  ROUTE_URL.resources_support.for_patients
                }
              >
                {t(
                  'landing.cards.additional_resources.for_patients.link_button.label'
                )}
              </InformationLink>{' '}
              {t('landing.cards.additional_resources.for_patients.body')}
            </PageText>
            <PageText textAlign="left">
              <InformationLink
                href={
                  ROUTE_URL.resources_support.index +
                  ROUTE_URL.resources_support.for_families
                }
              >
                {t(
                  'landing.cards.additional_resources.for_families.link_button.label'
                )}
              </InformationLink>{' '}
              {t('landing.cards.additional_resources.for_families.body')}
            </PageText>
            <PageText textAlign="left">
              <InformationLink
                href={
                  ROUTE_URL.resources_support.index +
                  ROUTE_URL.resources_support.for_professionals
                }
              >
                {t(
                  'landing.cards.additional_resources.for_professionals.link_button.label'
                )}
              </InformationLink>{' '}
              {t('landing.cards.additional_resources.for_professionals.body')}
            </PageText>
          </Stack>
        </ResponsiveCardStack>
      </ResponsiveRowContainer>

      <ResponsiveCardStack>
        <Stack spacing={1}>
          <ItalicHeading variant="h2" textAlign="left">
            {t('landing.cards.whosAccessRAAM.header')}
          </ItalicHeading>
          <PageText textAlign="left">
            {t('landing.cards.whosAccessRAAM.body1')}
          </PageText>
          <StyledUL>
            {[
              t('landing.cards.whosAccessRAAM.points.point1'),
              t('landing.cards.whosAccessRAAM.points.point2'),
              t('landing.cards.whosAccessRAAM.points.point3'),
            ].map((item: string, index) => {
              return <li key={keyGenerator(item, index)}>{item}</li>;
            })}
          </StyledUL>

          <PageText textAlign="left">
            {t('landing.cards.whosAccessRAAM.body2')}
          </PageText>
        </Stack>
      </ResponsiveCardStack>

      <ResponsiveCardStack>
        <Stack spacing={1}>
          <ItalicHeading variant="h2" textAlign="left">
            {t('landing.cards.whoAreWe.header')}
          </ItalicHeading>
          <PageText textAlign="left">
            {t('landing.cards.whoAreWe.body')}
          </PageText>
          <StyledUL>
            {[
              t('landing.cards.whoAreWe.points.point1'),
              t('landing.cards.whoAreWe.points.point2'),
              t('landing.cards.whoAreWe.points.point3'),
            ].map((item: string, index) => {
              return <li key={keyGenerator(item, index)}>{item}</li>;
            })}
          </StyledUL>
        </Stack>
      </ResponsiveCardStack>
      <RAAMClinicReconnectionPrompt
        open={isModalOpen}
        closeDialog={closeModal}
      />
    </Stack>
  );
}
