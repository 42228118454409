import { SvgIconProps, SvgIconTypeMap, SxProps, Theme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { memo } from 'react';
import * as CONSTANTS from '../../constants/colors';

interface StyledIconProps extends SvgIconProps {
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  colorVariant?: keyof typeof CONSTANTS;
  sx?: SxProps<Theme>;
}

const StyledIcon = ({
  icon: Icon,
  colorVariant,
  sx,
  ...restProps
}: StyledIconProps) => {
  return (
    <Icon
      sx={{
        color: CONSTANTS[colorVariant ?? 'DEEP_BLUE'],
        ...sx,
      }}
      {...restProps}
    />
  );
};

export default memo(StyledIcon);
