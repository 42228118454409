import { ConnectionService } from 'hive-client-utils';
import { useConnectionState } from 'hive-react-utils';
import { useMemo } from 'react';
import { LOGIN_SERVICE_FLAG } from '../config/featureFlags';
import Connecting from './Connecting';
import RouteElement from './Layout/RouteElement';
import NotImplemented from './NotImplemented';

export default function InternalMain(): JSX.Element {
  const connectionState = useConnectionState();

  const Component = useMemo<JSX.Element>(() => {
    switch (connectionState) {
      case ConnectionService.ConnectionState.CONNECTING:
      case ConnectionService.ConnectionState.CONNECTING_FROM_TOKEN:
        return <Connecting />;

      case ConnectionService.ConnectionState.CONNECTED:
      case ConnectionService.ConnectionState.CONNECTED_FROM_TOKEN:
        return <RouteElement />;

      default:
        return <NotImplemented />;
    }
  }, [connectionState]);

  if (LOGIN_SERVICE_FLAG) {
    return Component;
  }

  return <RouteElement />;
}
