import { Box } from '@mui/material';
import { ReactNode, memo } from 'react';

interface ModalContentGridProps {
  children: ReactNode;
}
export const ModalContentGrid = memo<ModalContentGridProps>(({ children }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: '1rem',
      }}
    >
      {children}
    </Box>
  );
});

ModalContentGrid.displayName = 'ModalContentGrid';
