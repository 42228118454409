import { Box, styled } from '@mui/material';
import { PALE_GRAY } from '../../constants/colors';

export const CircleWrapper = styled(Box)(() => ({
  width: 56,
  height: 56,
  borderRadius: '50%',
  backgroundColor: PALE_GRAY,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
