import { Box, styled } from '@mui/material';
import { memo } from 'react';

import ClinicHeroImageBackground from '../ClinicHeroImageBackground';
import Footer from '../Footer';
import Header from '../Header';
import ContentLayout from './ContentLayout';

const StyledMain = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%', //index.css has #root with height: 100vh
  position: 'relative',
  overflow: 'hidden',
});

function RouteElement(): JSX.Element {
  return (
    <StyledMain component="main">
      <Header />
      <ContentLayout />
      <Footer />
      <ClinicHeroImageBackground />
    </StyledMain>
  );
}

export default memo(RouteElement);
