import { Accordion, Box, Stack } from '@mui/material';
import { memo, useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ResourceSupportImage from '../assets/resources-support-landing-image.webp';
import { ContentText } from '../components/ResourceSupportPage/ContentText';
import { ContentTitle } from '../components/ResourceSupportPage/ContentTitle';
import { ResourceAccordionDetails } from '../components/ResourceSupportPage/ResourceAccordionDetails';
import { StyledAccordionSummary } from '../components/ResourceSupportPage/StyledAccordionSummary';
import { StyledAccordionTypography } from '../components/ResourceSupportPage/StyledAccordionTypography';
import { StyledStack } from '../components/ResourceSupportPage/StyledStack';
import ResponsiveImage from '../components/ResponsiveImage';
import { RESOURCE_SUPPORT } from '../constants/ResourceSupport';
import { useTranslation } from '../hooks/useKeyedTranslation';
import { keyGenerator } from '../utils';

export const ResourceSupportPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [expandedAccordion, setExpandedAccordion] = useState<string[]>([
    new URLSearchParams(location.search).get('open') ?? '',
  ]);

  useEffect(() => {
    if (expandedAccordion[0] === '') {
      return;
    }

    const el = document.querySelector(`#${expandedAccordion[0]}`);

    if (!el) {
      return;
    }

    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    // only need it run once hence, the empty array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAccordionChange = useCallback(
    (accordionId: string) => () => {
      setExpandedAccordion((prevExpandedAccordion) => {
        const isExpanded = prevExpandedAccordion.includes(accordionId);
        return isExpanded
          ? prevExpandedAccordion.filter((id) => id !== accordionId)
          : [...prevExpandedAccordion, accordionId];
      });
    },
    []
  );

  return (
    <>
      <StyledStack>
        <ContentTitle variant="h1">{t('resource_support.title')}</ContentTitle>
        <Stack direction={{ xs: 'column', sm: 'row' }} width="100%">
          <Box component="section">
            <ContentText textAlign="left">
              {t('resource_support.body1')}
            </ContentText>
            <br />
            <ContentText textAlign="left">
              {t('resource_support.body2')}
            </ContentText>
          </Box>
          <Box
            component="picture"
            display="flex"
            ml={{ xs: 0, sm: 2 }}
            mt={{ xs: 2, sm: 0 }}
          >
            <ResponsiveImage
              src={ResourceSupportImage}
              alt="Resource and Support Image"
              width={330}
              height={230}
              borderRadius={true}
            />
          </Box>
        </Stack>

        {RESOURCE_SUPPORT.map((supportItem) => (
          <Accordion
            id={supportItem.id}
            disableGutters
            elevation={0}
            square
            key={keyGenerator(supportItem.title)}
            expanded={expandedAccordion.includes(supportItem.id)}
            onChange={handleAccordionChange(supportItem.id)}
            sx={{
              width: '100%',
              '&.MuiAccordion-root:before': {
                opacity: 0,
              },
              borderRadius: '1rem',
              background: 'rgba(255, 255, 255, 0.80)',
              boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.25)',
            }}
          >
            <StyledAccordionSummary
              aria-controls="panel-content"
              id="panel-header"
            >
              <StyledAccordionTypography>
                {t(supportItem.title)}
              </StyledAccordionTypography>
            </StyledAccordionSummary>
            <ResourceAccordionDetails sections={supportItem.content} />
          </Accordion>
        ))}
      </StyledStack>
      <Outlet />
    </>
  );
};

ResourceSupportPage.displayName = 'ResourceSupportPage';

export default memo(ResourceSupportPage);
