/** Generally `Math.floor` or `Math.ceil`. */
type RoundingFunction = (x: number) => number;

export function round(
  x: number,
  multiple: number = 5,
  func: RoundingFunction = Math.ceil
) {
  return multiple * func(x / multiple);
}
