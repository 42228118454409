import { IconButton, Popover, SvgIconTypeMap, Tooltip } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { MouseEvent, memo, useCallback, useState } from 'react';
import { DARK_BLUE, PALE_GRAY } from '../../constants/colors';
import { PopoverContext } from '../../contexts/PopoverContext';
import IconWrapper from '../Icons/IconWrapper';

interface PopoverButtonProps {
  /** `aria-label` for the button. */
  label?: string;
  /** Icon to be displayed on the button. */
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  /** Element(s) to be displayed in the popover. */
  children?: JSX.Element | JSX.Element[];
}

/** Button that opens a popover with the child elements when clicked. */
const PopoverButton = ({ label, icon, children }: PopoverButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = !!anchorEl;
  const id = open ? 'simple-popover' : undefined;

  return (
    <PopoverContext.Provider value={{ closePopover: handleClose }}>
      <Tooltip title={label} disableInteractive>
        <IconButton
          aria-label={label}
          sx={{
            borderRadius: '8px',
            border: `2.5px solid ${open ? DARK_BLUE : PALE_GRAY}`,
            height: '1.75rem',
            width: '1.75rem',
            padding: '1rem',
            backgroundColor: open ? PALE_GRAY : 'white',
          }}
          onClick={handleClick}
        >
          <IconWrapper
            icon={icon}
            colorVariant={open ? 'WHITE' : 'DEEP_BLUE'}
          />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            border: `2px solid ${DARK_BLUE}`,
            borderRadius: '8px',
            padding: '4px 16px 16px 16px',
            width: '280px',
          },
        }}
        keepMounted
      >
        {children}
      </Popover>
    </PopoverContext.Provider>
  );
};

export default memo(PopoverButton);
