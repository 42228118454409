import { memo } from 'react';
import ClinicLinkDetails from './ClinicLinkDetails';
import { ClinicDetailsProps } from './ClinicTextDetails';

export const ClinicLocationDetails = memo(({ details }: ClinicDetailsProps) => (
  <ClinicLinkDetails
    details={details}
    href={`https://maps.google.com/?q=${encodeURIComponent(details)}`}
    tooltipI18n="find_clinic.tooltip.location_button"
  />
));
ClinicLocationDetails.displayName = 'ClinicLocationDetails';

export default ClinicLocationDetails;
