export const CONNECT_FLOW = {
  initial_assessment: {
    text: 'connect_now.header',
    nextQuestionId: {
      yes: 'select-clinic',
      no: 'find-clinic',
    },
  },
  select_clinic: {
    text: 'connect_now.select_clinic.header',
    label: 'connect_now.select_clinic.label',
    nextQuestionId: 'end',
  },
  find_clinic: {
    text: 'connect_now.find_clinic.header',
    nextQuestionId: 'end',
  },
} as const;
