import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import DFDClosed from '../../../assets/DFD_Closed.svg';
import DFDOpen from '../../../assets/DFD_Open.svg';
import { DARK_GRAY, DEEP_BLUE, WHITE } from '../../../constants/colors';
import { I18nAccess, useTranslation } from '../../../hooks/useKeyedTranslation';

interface LegendIconProps {
  i18nKey: I18nAccess;
  img: string;
}

const LegendIcon = ({ i18nKey, img }: LegendIconProps) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" gap={0.5}>
      <Typography
        color={DEEP_BLUE}
        fontSize="0.875rem"
        fontStyle="italic"
        fontWeight={500}
        lineHeight="1.25rem"
        letterSpacing="0.01806rem"
      >
        {t(i18nKey)}
      </Typography>
      <Box component="img" src={img} width="1.125rem" alignSelf="start" />
    </Box>
  );
};

const DFDIconLegend = () => {
  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      display="flex"
      borderRadius="0.25rem"
      border={`1px solid ${DARK_GRAY}`}
      bgcolor={WHITE}
      gap={1}
      paddingY={0.25}
    >
      <Typography
        flexBasis="content"
        color={DEEP_BLUE}
        fontWeight={700}
        fontSize="0.875rem"
        lineHeight="1.25rem"
        marginLeft={0.75}
      >
        {t('find_clinic.clinic_icon_legend.title')}
      </Typography>
      <LegendIcon i18nKey="find_clinic.clinic_icon_legend.open" img={DFDOpen} />
      <LegendIcon
        i18nKey="find_clinic.clinic_icon_legend.closed"
        img={DFDClosed}
      />
    </Box>
  );
};

export default memo(DFDIconLegend);
