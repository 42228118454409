import { ComponentsOverrides } from '@mui/material';

const headStyleOverrides: ComponentsOverrides['MuiTableHead'] = {
  root: {},
};

export const MuiTableHead = {
  styleOverrides: headStyleOverrides,
};

const rowStyleOverrides: ComponentsOverrides['MuiTableRow'] = {
  root: {},
};

export const MuiTableRow = {
  styleOverrides: rowStyleOverrides,
};

const cellStyleOverrides: ComponentsOverrides['MuiTableCell'] = {
  root: {},
};

export const MuiTableCell = {
  styleOverrides: cellStyleOverrides,
};

const styleOverrides: ComponentsOverrides['MuiTable'] = {
  root: {},
};

export const MuiTable = {
  styleOverrides,
};
