import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOCALSTORAGE_KEYS } from '../constants/localStorage';
import { ROUTE_URL } from '../constants/routes';
import { DFDClinicDataType } from '../utils';

export function useConnectNowButtonHandlers(): [
  boolean,
  () => void,
  () => void
] {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const clinic = useMemo(
    () =>
      JSON.parse(
        localStorage.getItem(LOCALSTORAGE_KEYS.previousClinic) ?? 'null'
      ) as DFDClinicDataType | null,
    []
  );

  const connectNowHandler = useCallback(() => {
    if (!clinic) {
      navigate(
        `${ROUTE_URL.connect_now.index}/${ROUTE_URL.connect_now.initial_assessment}`
      );
      return;
    }

    setIsModalOpen(true);
  }, [clinic, navigate]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return [isModalOpen, connectNowHandler, closeModal];
}
