import moment from 'moment';
import { ClinicDataType, TimeString, isDFDClinic } from './data';

export function timeStringToMoment(time: TimeString) {
  const [hours, minutes] = time.split(':').map(Number);
  return moment().set('hour', hours).set('minute', minutes);
}

export default function isClinicOpen(clinic: ClinicDataType) {
  if (isDFDClinic(clinic)) {
    const now = moment();
    const clinicHours = clinic.dfdHours[now.isoWeekday() % 7];
    if (!clinicHours.open) {
      return false;
    }
    return now.isBetween(
      timeStringToMoment(clinicHours.openHour),
      timeStringToMoment(clinicHours.closeHour),
      'minute',
      '[)'
    );
  }
  return false;
}
