import { ExpandMore } from '@mui/icons-material';
import {
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { memo, useCallback, useEffect, useRef } from 'react';
import { ClinicClickHandler } from '..';
import DFDClosed from '../../../assets/DFD_Closed.svg';
import DFDOpen from '../../../assets/DFD_Open.svg';
import {
  BORDER_BLUE,
  BORDER_TANGERINE,
  MEDIUM_BLUE,
  VIBRANT_TANGERINE,
} from '../../../constants/colors';
import { LOCALSTORAGE_KEYS } from '../../../constants/localStorage';
import { useClinicFilter } from '../../../hooks/useClinicFilter';
import { useTranslation } from '../../../hooks/useKeyedTranslation';
import { useScrollContext } from '../../../hooks/useScrollContext';
import {
  ClinicDataType,
  DFDClinicDataType,
  isClinicOpen,
  isDFDClinic,
} from '../../../utils/';
import StyledIcon from '../../Icons/IconWrapper';
import ClinicDetails from './ClinicDetails';
import { StyledAccordion } from './StyledAccordion';
import VirtualCheckInButton from './VirtualCheckInButton';

interface ClinicListItemSummaryProps {
  clinic: ClinicDataType;
  index: number;
  isSelected: boolean;
}

const ClinicListItemSummary = memo(
  ({ clinic, index, isSelected }: ClinicListItemSummaryProps) => {
    const { t } = useTranslation();
    const clinicId = clinic.name.replace(/ /g, '-') + '-list-header';

    return (
      <AccordionSummary
        expandIcon={<StyledIcon icon={ExpandMore} />}
        aria-controls={clinic.name + '-' + index + '-content'}
        id={clinicId + '-' + index}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="start"
          spacing={2}
          width="100%"
        >
          <Avatar
            sx={{
              bgcolor: isSelected ? MEDIUM_BLUE : VIBRANT_TANGERINE,
              border: `1.1px solid ${
                isSelected ? BORDER_BLUE : BORDER_TANGERINE
              }`,
              boxShadow: '2px 2px 2px 0px black',
              height: 25,
              width: 25,
              fontSize: '1em',
              fontWeight: '600',
              lineHeight: '1.0625em',
              color: 'white',
              transition: 'background-color 0.25s ease',
            }}
            variant="circular"
          >
            {index + 1}
          </Avatar>
          <Typography
            fontWeight="700"
            lineHeight="18px"
            color="#393939"
            fontStyle="normal"
          >
            {clinic.name}
          </Typography>
          {isDFDClinic(clinic) && (
            <Box
              component="img"
              src={isClinicOpen(clinic) ? DFDOpen : DFDClosed}
              title={
                isClinicOpen(clinic)
                  ? t('find_clinic.button.clinic_status_icon.open')
                  : t('find_clinic.button.clinic_status_icon.closed')
              }
              marginLeft="0.5rem !important"
            />
          )}
        </Stack>
      </AccordionSummary>
    );
  }
);
ClinicListItemSummary.displayName = 'ClinicListItemSummary';

interface ClinicListItemDetailsProps {
  clinic: ClinicDataType;
}

const ClinicListItemDetails = memo(({ clinic }: ClinicListItemDetailsProps) => {
  const onDFDClickHandler = useCallback((clinicData: DFDClinicDataType) => {
    return () =>
      window.localStorage.setItem(
        LOCALSTORAGE_KEYS.previousClinic,
        JSON.stringify(clinicData)
      );
  }, []);

  return (
    <AccordionDetails
      sx={{
        px: {
          xs: '1em',
        },
      }}
    >
      <Grid
        container
        rowSpacing={{
          xs: 0.5,
          sm: 1,
        }}
      >
        <ClinicDetails
          item
          clinic={clinic}
          rows={['description', 'hours', 'telephone', 'website', 'address']}
        />
        {isDFDClinic(clinic) && (
          <Grid
            item
            xs={12}
            justifyContent="center"
            display="flex"
            my={{
              xs: 1,
              sm: 0,
            }}
          >
            <VirtualCheckInButton
              website={`${clinic.dfdLink}?referrer=ofd&check_in=true`}
              onClickHandler={onDFDClickHandler(clinic)}
            />
          </Grid>
        )}
      </Grid>
    </AccordionDetails>
  );
});
ClinicListItemDetails.displayName = 'ClinicListItemDetails';

interface ClinicListItemContentProps extends ClinicListItemProps {
  isVisible: boolean;
  isSelected: boolean;
}

const ClinicListItemContent = memo(
  ({
    clinic,
    index,
    onClinicClick,
    displayIndex,
    isVisible,
    isSelected,
  }: ClinicListItemContentProps) => {
    const { addRef, removeRef } = useScrollContext();
    const clinicListItemRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      addRef(index, clinicListItemRef);

      return () => removeRef(index);
    }, [addRef, index, removeRef]);

    const onAccordionClick = useCallback(
      (_event: any, expanded: boolean) => {
        if (expanded) {
          onClinicClick(index, clinic)();
        } else {
          onClinicClick(null)();
        }
      },
      [onClinicClick, index, clinic]
    );

    return (
      <StyledAccordion
        onChange={onAccordionClick}
        ref={clinicListItemRef}
        expanded={isSelected}
        TransitionProps={{ unmountOnExit: true }}
        disableGutters
        sx={isVisible ? {} : { display: 'none' }}
      >
        <ClinicListItemSummary
          clinic={clinic}
          index={displayIndex}
          isSelected={isSelected}
        />
        <ClinicListItemDetails clinic={clinic} />
      </StyledAccordion>
    );
  }
);
ClinicListItemContent.displayName = 'ClinicListItemContent';

interface ClinicListItemProps {
  clinic: ClinicDataType;
  index: number;
  onClinicClick: ClinicClickHandler;
  displayIndex: number;
}

// useClinicFilter changes on every ClinicFilterContext update but IS_VISIBLE won't change as often,
// so separating this logic from the rendered component improves the effectiveness of the memoization.
const ClinicListItem = memo(
  ({ clinic, index, onClinicClick, displayIndex }: ClinicListItemProps) => {
    const clinicFilter = useClinicFilter();
    const IS_VISIBLE = clinicFilter(clinic, index);
    const { selectedRef } = useScrollContext();

    const IS_SELECTED = selectedRef === index;

    return (
      <ClinicListItemContent
        clinic={clinic}
        index={index}
        onClinicClick={onClinicClick}
        isVisible={IS_VISIBLE}
        displayIndex={IS_VISIBLE || IS_SELECTED ? displayIndex : 0}
        isSelected={IS_SELECTED}
      />
    );
  }
);
ClinicListItem.displayName = 'ClinicListItem';

export { ClinicListItem };
export default ClinicListItem;
