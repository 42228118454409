import {
  AccessTime,
  Call,
  InfoOutlined,
  MyLocation,
  PersonalVideo,
  Public,
} from '@mui/icons-material';
import StyledIcon from '../../../Icons/IconWrapper';

export type ClinicListSchemaType = {
  icon: JSX.Element;
  content:
    | { type: 'text'; details: 'description' | 'hours' }
    | { type: 'tel'; details: 'telephone' }
    | { type: 'link'; details: 'website' | 'dfdLink' }
    | { type: 'location'; details: 'address' };
};

export const DETAIL_SCHEMAS: {
  [Key in ClinicListSchemaType['content']['details']]: ClinicListSchemaType;
} = {
  description: {
    icon: <StyledIcon icon={InfoOutlined} />,
    content: {
      type: 'text',
      details: 'description',
    },
  },
  hours: {
    icon: <StyledIcon icon={AccessTime} />,
    content: {
      type: 'text',
      details: 'hours',
    },
  },
  telephone: {
    icon: <StyledIcon icon={Call} />,
    content: {
      type: 'tel',
      details: 'telephone',
    },
  },
  website: {
    icon: <StyledIcon icon={Public} />,
    content: {
      type: 'link',
      details: 'website',
    },
  },
  dfdLink: {
    icon: <StyledIcon icon={PersonalVideo} />,
    content: {
      type: 'link',
      details: 'dfdLink',
    },
  },
  address: {
    icon: <StyledIcon icon={MyLocation} />,
    content: {
      type: 'location',
      details: 'address',
    },
  },
};
