import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { memo, useCallback } from 'react';
import { useConnectNowContext } from '../../hooks/useConnectNowContext';
import { I18nAccess, useTranslation } from '../../hooks/useKeyedTranslation';

const StyledStack = styled(Stack)(() => ({
  height: '100%',
  alignItems: 'start',
  background: 'rgba(255, 255, 255, 0.8)',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '16px',
  padding: '1.5em',
  flexGrow: 1,
  justifyContent: 'space-between',
  maxHeight: '300px',
  backdropFilter: 'blur(6.7957px)',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 400,
  fontStyle: 'italic',
  lineHeight: '2.0625rem',
  color: theme.palette.primary.main,
}));

const ResponsiveBox = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  justifyContent: 'center',
  gap: theme.spacing(3),
}));

export interface ConsultedRAAMQuestionProps {
  text: I18nAccess;
  nextQuestionId: {
    yes: string;
    no: string;
  };
}

function ConsultedRAAMQuestion({
  text,
  nextQuestionId,
}: ConsultedRAAMQuestionProps) {
  const { t } = useTranslation();
  const { navigateToNextQuestion } = useConnectNowContext();

  const onYesHandler = useCallback(() => {
    if (!nextQuestionId?.yes) {
      console.error('No next question id');
      return;
    }

    navigateToNextQuestion(nextQuestionId.yes);
  }, [navigateToNextQuestion, nextQuestionId?.yes]);

  const onNoHandler = useCallback(() => {
    if (!nextQuestionId?.no) {
      console.error('No next question id');
      return;
    }

    navigateToNextQuestion(nextQuestionId.no);
  }, [navigateToNextQuestion, nextQuestionId?.no]);

  return (
    <StyledStack spacing={3}>
      <StyledTypography textAlign="left">{t(text)}</StyledTypography>
      <ResponsiveBox component="span">
        <Button
          role="button"
          type="button"
          variant="contained"
          color="primary"
          title={t('connect_now.yes_button.title')}
          aria-label={t('connect_now.yes_button.aria_label')}
          onClick={onYesHandler}
          disabled={!nextQuestionId?.yes}
        >
          {t('connect_now.yes_button.label')}
        </Button>
        <Button
          role="button"
          type="button"
          variant="contained"
          color="primary"
          title={t('connect_now.no_button.title')}
          aria-label={t('connect_now.no_button.aria_label')}
          onClick={onNoHandler}
          disabled={!nextQuestionId?.no}
        >
          {t('connect_now.no_button.label')}
        </Button>
      </ResponsiveBox>
    </StyledStack>
  );
}

export default memo(ConsultedRAAMQuestion);
