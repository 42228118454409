import { Box } from '@mui/material';
import { memo } from 'react';
import { ModalContent } from '../../../constants/ResourceSupport';
import { useTranslation } from '../../../hooks/useKeyedTranslation';
import { keyGenerator } from '../../../utils';
import { StyledSubContentTypography } from '../StyledSubContentTypography';
import { StyledSubHeadingTypography } from '../StyledSubHeadingTypography';

interface SectionProps {
  section: ModalContent;
}

export const Section = memo<SectionProps>(({ section }) => {
  const { t } = useTranslation();
  return (
    <Box key={keyGenerator(section.title)} marginBottom="1rem">
      <StyledSubHeadingTypography
        marginTop="1rem"
        marginBottom="0.5rem"
        textAlign="left"
        fontStyle="italic !important" //override 'normal' fontStyle
      >
        {t(section.title)}
      </StyledSubHeadingTypography>
      <StyledSubContentTypography>
        {t(section.content)}
      </StyledSubContentTypography>
    </Box>
  );
});
