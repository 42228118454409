// Modified from https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api

import narrowToLatLngLiteral from './narrowToLatLngLiteral';

/** Radius of the Earth in kilometres */
const EARTH_RADIUS = 6378.1;

/** Function to calculate the distance between two points on a sphere.
 * This assumes the Earth is perfectly circular, so it can be a couple hundred metres off. */
export default function haversineDistance(
  from: google.maps.LatLng | google.maps.LatLngLiteral,
  to: google.maps.LatLng | google.maps.LatLngLiteral
) {
  from = narrowToLatLngLiteral(from);
  to = narrowToLatLngLiteral(to);

  const LAT_RADIANS_FROM = from.lat * (Math.PI / 180); // Convert degrees to radians
  const LAT_RADIANS_TO = to.lat * (Math.PI / 180); // Convert degrees to radians
  const LAT_DIFF = LAT_RADIANS_TO - LAT_RADIANS_FROM; // Radian difference (latitudes)
  const LNG_DIFF = (to.lng - from.lng) * (Math.PI / 180); // Radian difference (longitudes)

  return (
    2 *
    EARTH_RADIUS *
    Math.asin(
      Math.sqrt(
        Math.sin(LAT_DIFF / 2) * Math.sin(LAT_DIFF / 2) +
          Math.cos(LAT_RADIANS_FROM) *
            Math.cos(LAT_RADIANS_TO) *
            Math.sin(LNG_DIFF / 2) *
            Math.sin(LNG_DIFF / 2)
      )
    )
  );
}
