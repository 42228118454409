import { InfoOutlined, MyLocation } from '@mui/icons-material';
import { Box, Button, CircularProgress } from '@mui/material';
import {
  MutableRefObject,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useGeoLocationCallback } from '../hooks/useGeoLocationCallback';
import { useIsMobile } from '../hooks/useIsMobile';
import { I18nAccess, useTranslation } from '../hooks/useKeyedTranslation';
import { getGeolocationErrorReason } from '../utils/getGeolocationErrorReason';
import IconWrapper from './Icons/IconWrapper';
import { StyledTooltip } from './StyledTooltip';

interface GeolocationButtonProps {
  onInitialClick?: () => void;
  onSuccess?: (coords: google.maps.LatLngLiteral) => void;
  onFailure?: (error: GeolocationPositionError) => void;
  onCompletion?: () => void;
  updateContexts?: boolean;
  executeCallback?: MutableRefObject<boolean>;
}

const GeolocationButton = ({
  onInitialClick,
  onSuccess,
  onFailure,
  onCompletion,
  updateContexts = true,
  executeCallback,
}: GeolocationButtonProps) => {
  const acquireGeoLocation = useGeoLocationCallback();

  const [geolocationError, setGeolocationError] =
    useState<GeolocationPositionError>();
  const [geolocationPermission, setGeolocationPermission] =
    useState<PermissionState>();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const infoMessageKey = useMemo<I18nAccess>(() => {
    return isLoading
      ? 'find_clinic.input_location.info.waiting'
      : getGeolocationErrorReason(geolocationPermission, geolocationError);
  }, [isLoading, geolocationPermission, geolocationError]);

  const handleUseGeolocation = useCallback(() => {
    if (acquireGeoLocation) {
      onInitialClick?.();
      setIsLoading(true);
      acquireGeoLocation(updateContexts, executeCallback, (result) => {
        if ('lat' in result) {
          onSuccess?.(result);
          setGeolocationError(undefined);
          setGeolocationPermission(undefined);
        } else {
          onFailure?.(result);
          setGeolocationError(result);
        }
        onCompletion?.();
        setIsLoading(false);
      });
    }
  }, [
    acquireGeoLocation,
    onInitialClick,
    updateContexts,
    executeCallback,
    onSuccess,
    onFailure,
    onCompletion,
  ]);

  useEffect(() => {
    const controller = new AbortController();

    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (controller.signal.aborted) {
        return;
      }

      setGeolocationPermission(result.state);

      const changeEventListener = () => {
        setGeolocationPermission(result.state);

        if (result.state !== 'denied') {
          setGeolocationError(undefined);
        }
      };

      result.addEventListener('change', changeEventListener);
      controller.signal.addEventListener('abort', () => {
        result.removeEventListener('change', changeEventListener);
      });
    });

    return () => controller.abort();
  }, []);

  const isMobile = useIsMobile();

  const someError =
    geolocationError ||
    geolocationPermission === 'denied' ||
    !window.isSecureContext;

  if (!acquireGeoLocation) {
    return null;
  }

  const StartIcon = isLoading ? CircularProgress : MyLocation;

  return (
    <Box display="flex" alignItems="center" gap="0.5rem">
      <Button
        id="request-geolocation-btn"
        type="button"
        variant="outlined"
        onClick={handleUseGeolocation}
        color={someError ? 'error' : 'primary'}
        startIcon={
          <StartIcon
            color={isLoading ? 'inherit' : someError ? 'error' : 'primary'}
            size={20}
          />
        }
        disabled={isLoading}
        aria-label={
          isLoading
            ? t('find_clinic.input_location.geolocation_wait_aria_label')
            : t('find_clinic.input_location.automatic')
        }
        aria-errormessage={someError ? t(infoMessageKey) : undefined}
      >
        {t('find_clinic.input_location.automatic')}
      </Button>
      <StyledTooltip
        placement={isMobile ? 'left' : 'right'}
        title={t(infoMessageKey)}
        describeChild
      >
        <Box display="flex" id="geolocation-info-container">
          <IconWrapper
            colorVariant={someError ? 'ERROR' : 'DEEP_BLUE'}
            icon={InfoOutlined}
            aria-describedby="geolocation-info-container"
          />
        </Box>
      </StyledTooltip>
    </Box>
  );
};

export default memo(GeolocationButton);
