import { Box, styled } from '@mui/material';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';

const StyledContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  maxWidth: '1152px',
  minWidth: 0,
  paddingLeft: '1rem',
  paddingRight: 'calc(1rem - 100vw + 100%)',

  [theme.breakpoints.up('sm')]: {
    paddingLeft: '1.5rem',
    paddingRight: 'calc(1.5rem - 100vw + 100%)',
  },
}));

const ContentLayout = memo(() => {
  return (
    <Box display="flex" flexGrow={1} justifyContent="center" width="100%">
      <StyledContainer>
        <Outlet />
      </StyledContainer>
    </Box>
  );
});

ContentLayout.displayName = 'ContentLayout';

export default ContentLayout;
