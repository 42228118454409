import {
  Dispatch,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { LOCALSTORAGE_KEYS } from '../constants/localStorage';
import { ROUTE_URL } from '../constants/routes';
import { QuestionType } from '../pages/ConnectNowPage';
import { OFDClinic } from '../utils/data';

export interface ConnectNowContextState {
  questionType: QuestionType;
  selectedOption: string;
  postalCode: string;
}

export const initialState: ConnectNowContextState = {
  questionType: 'initial_assessment',
  selectedOption: '',
  postalCode: '',
};

export enum ConnectNowContextActionType {
  SET_QUESTION_TYPE = 'SET_QUESTION_TYPE',
  SET_SELECTED_OPTION = 'SET_SELECTED_OPTION',
  SET_POSTAL_CODE = 'SET_POSTAL_CODE',
}

export type ConnectNowContextAction =
  | {
      type: ConnectNowContextActionType.SET_QUESTION_TYPE;
      payload: {
        questionType: QuestionType;
      };
    }
  | {
      type: ConnectNowContextActionType.SET_SELECTED_OPTION;
      payload: {
        selectedOption: string;
      };
    }
  | {
      type: ConnectNowContextActionType.SET_POSTAL_CODE;
      payload: {
        postalCode: string;
      };
    };

export function connectNowReducer(
  state: ConnectNowContextState,
  action: ConnectNowContextAction
): ConnectNowContextState {
  switch (action.type) {
    case ConnectNowContextActionType.SET_QUESTION_TYPE:
      return {
        ...state,
        questionType: action.payload.questionType,
      };
    case ConnectNowContextActionType.SET_SELECTED_OPTION:
      return {
        ...state,
        selectedOption: action.payload.selectedOption,
      };
    case ConnectNowContextActionType.SET_POSTAL_CODE:
      return {
        ...state,
        postalCode: action.payload.postalCode,
      };
    default:
      return state;
  }
}

export interface ConnectNowContextType {
  state: ConnectNowContextState;
  dispatch: Dispatch<ConnectNowContextAction>;
  navigateToNextQuestion: (nextQuestionId: string) => void;
  onSelectClinicHandler: (selected: string) => void;
  onContinueClinicHandler: (clinicList: OFDClinic[]) => void;
  setPostalCode: (postalCode: string) => void;
  onContinuePostalCode: () => void;
  onContinueGeolocation: (geolocation: google.maps.LatLngLiteral) => void;
}

export const ConnectNowContext = createContext<ConnectNowContextType>({
  state: initialState,
  dispatch: () => {},
  navigateToNextQuestion: () => {},
  onSelectClinicHandler: () => {},
  onContinueClinicHandler: () => {},
  setPostalCode: () => {},
  onContinuePostalCode: () => {},
  onContinueGeolocation: () => {},
});

// name Context hook to show up in React DevTools
ConnectNowContext.displayName = 'ConnectNowContext';

export function ConnectNowContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(connectNowReducer, initialState);

  const navigateToNextQuestion = useCallback(
    (nextQuestionId: string) => {
      navigate(`${ROUTE_URL.connect_now.index}/${nextQuestionId}`, {
        replace: false,
      });
    },
    [navigate]
  );

  const onSelectClinicHandler = useCallback((selected: string) => {
    dispatch({
      type: ConnectNowContextActionType.SET_SELECTED_OPTION,
      payload: {
        selectedOption: selected,
      },
    });
  }, []);

  const onContinueClinicHandler = useCallback(
    (clinicList: OFDClinic[]) => {
      const selectedClinic = clinicList.find(
        (option) => option?.name === state.selectedOption
      );

      if (!selectedClinic) {
        console.error('No clinic found');
        return;
      }

      localStorage.setItem(
        LOCALSTORAGE_KEYS.previousClinic,
        JSON.stringify(selectedClinic)
      );

      window.location.href =
        selectedClinic.dfdLink + '?referrer=ofd&check_in=true';
    },
    [state.selectedOption]
  );

  const setPostalCode = useCallback((postalCode: string) => {
    dispatch({
      type: ConnectNowContextActionType.SET_POSTAL_CODE,
      payload: {
        postalCode,
      },
    });
  }, []);

  const onContinuePostalCode = useCallback(() => {
    navigate(
      `${ROUTE_URL.find_clinic.results}/?postalCode=${state.postalCode}`,
      {
        replace: false,
      }
    );
  }, [navigate, state.postalCode]);

  const onContinueGeolocation = useCallback(
    (geolocation: google.maps.LatLngLiteral) => {
      navigate(
        `${ROUTE_URL.find_clinic.results}?lat=${geolocation.lat}&lng=${geolocation.lng}`,
        {
          replace: false,
        }
      );
    },
    [navigate]
  );

  // avoid inline object inside value to avoid rerendering
  const contextValue = useMemo(() => {
    return {
      state,
      dispatch,
      navigateToNextQuestion,
      onSelectClinicHandler,
      onContinueClinicHandler,
      setPostalCode,
      onContinuePostalCode,
      onContinueGeolocation,
    };
  }, [
    state,
    dispatch,
    navigateToNextQuestion,
    onSelectClinicHandler,
    onContinueClinicHandler,
    setPostalCode,
    onContinuePostalCode,
    onContinueGeolocation,
  ]);

  return (
    <ConnectNowContext.Provider value={contextValue}>
      {children}
    </ConnectNowContext.Provider>
  );
}

export function useConnectNowContext() {
  const {
    state,
    navigateToNextQuestion,
    onSelectClinicHandler,
    onContinueClinicHandler,
    setPostalCode,
    onContinuePostalCode,
    onContinueGeolocation,
  } = useContext(ConnectNowContext);

  return {
    state,
    navigateToNextQuestion,
    onSelectClinicHandler,
    onContinueClinicHandler,
    setPostalCode,
    onContinuePostalCode,
    onContinueGeolocation,
  };
}
