import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { memo, useCallback, useRef } from 'react';
import { useConnectNowContext } from '../../hooks/useConnectNowContext';
import { I18nAccess, useTranslation } from '../../hooks/useKeyedTranslation';
import { isValidPostalCode } from '../../utils';
import GeolocationButton from '../GeolocationButton';
import PostalCodeTextField from '../PostalCodeTextField';

const StyledStack = styled(Stack)(() => ({
  height: '100%',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.8)',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
  borderRadius: '16px',
  padding: '1.5em',
  flexGrow: 1,
  justifyContent: 'space-between',
  maxHeight: '300px',
  backdropFilter: 'blur(6.7957px)',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  fontStyle: 'italic',
  lineHeight: '1.1875rem',
  color: theme.palette.primary.main,
}));

const ResponsiveBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export interface PostalCodeQuestionProps {
  text: I18nAccess;
}

export function PostalCodeQuestion({ text }: PostalCodeQuestionProps) {
  const { t } = useTranslation();
  const { state, setPostalCode, onContinuePostalCode, onContinueGeolocation } =
    useConnectNowContext();

  const shouldNavigate = useRef(true);

  const onChangeHandler = useCallback(
    (postalCode: string) => {
      setPostalCode(postalCode);
    },
    [setPostalCode]
  );

  const postalCodeContinueHandler = useCallback(() => {
    if (isValidPostalCode(state.postalCode) && shouldNavigate.current) {
      shouldNavigate.current = false;
      onContinuePostalCode();
    }
  }, [onContinuePostalCode, state.postalCode]);

  const geolocationContinueHandler = useCallback(
    (coords: google.maps.LatLngLiteral) => {
      if (shouldNavigate.current) {
        shouldNavigate.current = false;
        onContinueGeolocation(coords);
      }
    },
    [onContinueGeolocation]
  );

  return (
    <StyledStack spacing={3}>
      <ResponsiveBox>
        <StyledTypography>{t(text)}</StyledTypography>
        <PostalCodeTextField
          showSubmitArrow={true}
          smallPlaceholder={true}
          postalCode={state.postalCode}
          setPostalCode={onChangeHandler}
          handleSubmit={postalCodeContinueHandler}
        />
      </ResponsiveBox>

      <Box display="flex" justifyContent="center" width="100%" gap="1.5rem">
        <Button
          role="button"
          type="button"
          variant="contained"
          color="primary"
          title={t('connect_now.continue_button.title')}
          aria-label={t('connect_now.continue_button.aria_label')}
          onClick={postalCodeContinueHandler}
          disabled={!isValidPostalCode(state.postalCode)}
        >
          {t('connect_now.continue_button.label')}
        </Button>
        <GeolocationButton onSuccess={geolocationContinueHandler} />
      </Box>
    </StyledStack>
  );
}

export default memo(PostalCodeQuestion);
