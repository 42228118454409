import { haversineDistance, narrowToLatLngLiteral } from '.';

/** Does its best to guarantee that the map will completely show a circle with radius `distance`. */
export const setMapZoomByDistance = (
  map: google.maps.Map,
  distance: number
) => {
  distance ||= 0.1;

  const bounds = map.getBounds();
  const zoom = map.getZoom();

  if (!bounds || zoom === undefined) {
    return;
  }

  if (zoom < 2) {
    // Code breaks cause you can see more than the whole world, so zoom in and try again
    map.setZoom(2);
    setMapZoomByDistance(map, distance);
    return;
  }

  const { lat: up, lng: right } = narrowToLatLngLiteral(bounds.getNorthEast());
  const { lat: down, lng: left } = narrowToLatLngLiteral(bounds.getSouthWest());

  // Rough width and height through the centre of the map
  const width = haversineDistance(
    { lat: (up + down) / 2, lng: left },
    { lat: (up + down) / 2, lng: right }
  );
  const height = haversineDistance(
    { lat: up, lng: (left + right) / 2 },
    { lat: down, lng: (left + right) / 2 }
  );

  const radius = Math.min(width, height) / 2;

  // Each zoom level increment doubles the size of map
  // Calculate how much larger/smaller the map should be, then add it to the current zoom
  map.setZoom(zoom + Math.log2(radius / distance));
};

// Guesses how much to set the map zoom based on predefined measurements.
// Not perfectly accurate for all screens, but good enough for initial load.
export const guessMapZoomByDistance = (distance: number, isMobile: boolean) => {
  const zoom18 = isMobile ? 0.06349141817755588 : 0.1321658092641837;
  return 18 + Math.log2(zoom18 / distance);
};
