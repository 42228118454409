import { Grid } from '@mui/material';
import { memo } from 'react';
import { ClinicDataType, isDFDClinic } from '../../../../utils';
import { ClinicLinkDetails } from './ClinicLinkDetails';
import { ClinicListSchemaType } from './ClinicListSchema';
import { ClinicLocationDetails } from './ClinicLocationDetails';
import { ClinicPhoneDetailsFactory } from './ClinicPhoneDetails';
import { ClinicTextDetails } from './ClinicTextDetails';

const COMPONENT_MAP = {
  text: ClinicTextDetails,
  link: ClinicLinkDetails,
  tel: ClinicPhoneDetailsFactory,
  location: ClinicLocationDetails,
};

export interface ClinicDetailRowProps {
  clinicSchema: ClinicListSchemaType;
  clinic: ClinicDataType;
}

export const ClinicDetailRowFactory = memo(
  ({ clinicSchema, clinic }: ClinicDetailRowProps) => {
    // These variables must be assigned inside the switch case or else TypeScript gets mad
    let component: JSX.Element;
    let ComponentToRender: (typeof COMPONENT_MAP)[keyof typeof COMPONENT_MAP];
    let details: string | undefined;

    switch (clinicSchema.content.details) {
      case 'telephone':
        ComponentToRender = COMPONENT_MAP[clinicSchema.content.type];
        component = (
          <ComponentToRender details={clinic[clinicSchema.content.details]} />
        );
        break;
      case 'dfdLink':
        ComponentToRender = COMPONENT_MAP[clinicSchema.content.type];
        if (!isDFDClinic(clinic)) {
          return null;
        }
        details = clinic[clinicSchema.content.details];
        component = <ComponentToRender details={details} />;
        break;
      default:
        ComponentToRender = COMPONENT_MAP[clinicSchema.content.type];
        details = clinic[clinicSchema.content.details];
        if (!details) {
          return null;
        }
        component = <ComponentToRender details={details} />;
    }

    return (
      <>
        <Grid
          item
          xs={1}
          display="flex"
          justifyContent="right"
          paddingRight="0.25rem"
        >
          {clinicSchema.icon}
        </Grid>
        <Grid item xs={9}>
          {component}
        </Grid>
      </>
    );
  }
);
ClinicDetailRowFactory.displayName = 'ClinicDetailRowFactory';
