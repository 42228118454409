import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import FindClinicResults from './components/FindClinic';
import InternalMain from './components/InternalMain';
import ConnectNowLayout from './components/Layout/ConnectNowLayout';
import NotImplemented from './components/NotImplemented';
import PostalCodeInput from './components/PostalCodeInput';
import ResourceModal from './components/ResourceSupportPage/Modal/ResourceModal';
import { ROUTE_URL } from './constants/routes';
import ConnectNowPage from './pages/ConnectNowPage';
import FindClinicPage from './pages/FindClinic';
import LandingPage from './pages/LandingPage';
import { ResourceSupportPage } from './pages/ResourcesSupportPage';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={ROUTE_URL.base} element={<InternalMain />}>
        <Route index element={<LandingPage />} />
        <Route path={ROUTE_URL.find_clinic.index} element={<FindClinicPage />}>
          <Route index element={<PostalCodeInput />} />
          <Route
            path={ROUTE_URL.find_clinic.results}
            element={<FindClinicResults />}
          />
        </Route>
        <Route
          path={ROUTE_URL.connect_now.index}
          element={<ConnectNowLayout />}
        >
          <Route
            index
            path={ROUTE_URL.connect_now.questionId}
            element={<ConnectNowPage />}
          />
          <Route
            path={ROUTE_URL.connect_now.find_clinic_results}
            element={<FindClinicPage />}
          >
            <Route index element={<FindClinicResults dfdOnly />} />
          </Route>
        </Route>
        <Route
          path={ROUTE_URL.resources_support.index}
          element={<ResourceSupportPage />}
        >
          <Route
            index
            path={ROUTE_URL.resources_support.resourceId}
            element={<ResourceModal />}
          />
        </Route>
        <Route path="*" element={<NotImplemented />} />
      </Route>
    </>
  )
);

export default function Main(): JSX.Element {
  return <RouterProvider router={router}></RouterProvider>;
}
