import { createTheme, ThemeOptions } from '@mui/material';
import { MuiAccordion } from './accordion';
import { MuiDialogTitle } from './dialog';
import { MuiInputBase } from './input';
import { palette } from './palette';
import { MuiPaper } from './paper';
import { MuiTab, MuiTabs } from './tab';
import { MuiTable, MuiTableCell, MuiTableHead, MuiTableRow } from './table';
import { MuiToolbar } from './toolbar';
import { MuiTypography } from './typography';

const updates: ThemeOptions = {
  components: {
    MuiAccordion,
    MuiDialogTitle,
    MuiInputBase,
    MuiPaper,
    MuiTab,
    MuiTabs,
    MuiTable,
    MuiTableCell,
    MuiTableHead,
    MuiTableRow,
    MuiToolbar,
    MuiTypography,
  },
  palette,
};

export const theme = createTheme(updates);
