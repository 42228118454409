import { ArrowBack } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';
import { useTranslation } from '../../hooks/useKeyedTranslation';

interface BackIconButtonProps {
  handleClose: () => void;
}

type RestIconButtonProps = Omit<IconButtonProps, 'children'>;

export function BackIconButton({
  handleClose,
  ...rest
}: BackIconButtonProps & RestIconButtonProps) {
  const { t } = useTranslation();
  return (
    <IconButton
      aria-label={t('common.button.back.aria_label')}
      title={t('common.button.back.title')}
      onClick={handleClose}
      {...rest}
    >
      <ArrowBack color="primary" />
    </IconButton>
  );
}
