import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionSummaryProps,
  AccordionSummary as MuiAccordionSummary,
  styled,
} from '@mui/material';

export const StyledInnerAccordionSummary = styled(
  (props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
  )
)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
