import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionSummaryProps,
  AccordionSummary as MuiAccordionSummary,
  styled,
} from '@mui/material';

export const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(() => ({
  marginTop: '0.5rem',
  marginBottom: '0.5rem',
}));
