import { DEEP_BLUE } from '../constants/colors';
export const palette = {
  primary: {
    main: DEEP_BLUE,

    text: {
      default: DEEP_BLUE,
    },
  },
};
