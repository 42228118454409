import { ComponentsOverrides, Theme } from '@mui/material';
import { DEEP_BLUE } from '../constants/colors';

const styleOverrides: ComponentsOverrides<Theme>['MuiTypography'] = {
  h5: {
    fontStyle: 'italic',
    '.patient-chart &': {
      fontStyle: 'italic',
    },
  },
  body1: {
    'header .MuiIconButton-root &': {
      textTransform: 'capitalize',
    },
    '&.find-a-clinic--body': {
      color: DEEP_BLUE,
      lineHeight: '1.1875rem',
      fontSize: '0.875rem',
      textAlign: 'left',
    },
    '&.find-a-clinic-center': {
      textAlign: 'center',
    },
    '&.find-a-clinic--emergency-message': {
      fontStyle: 'italic',
      textAlign: 'center',
    },
  },
  h2: {
    '&.find-a-clinic--header': {
      color: DEEP_BLUE,
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: '1.375rem',
      lineHeight: '1.625rem',
    },
  },
};

export const MuiTypography = {
  styleOverrides,
  allVariants: {
    color: DEEP_BLUE,
  },
};
