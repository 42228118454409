const API_KEY = (process.env.REACT_APP_GOOGLE_API_KEY ||
  (window as any)._env_.REACT_APP_GOOGLE_API_KEY) as string;

/** The value returned by Google when it can't find the postal code */
const CENTRE_OF_CANADA = {
  lat: 56.130366,
  lng: -106.346771,
};

export async function getCoordinatesFromPostalCode(
  postalCode: string
): Promise<google.maps.LatLngLiteral> {
  const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    postalCode
  )},+Canada&key=${API_KEY}`;

  const response = await fetch(geocodeUrl);
  const data = await response.json();

  if (data.status === 'OK' && data.results.length > 0) {
    const location = data.results[0].geometry.location;
    const latitude: number = location.lat;
    const longitude: number = location.lng;

    if (
      latitude === CENTRE_OF_CANADA.lat &&
      longitude === CENTRE_OF_CANADA.lng
    ) {
      throw new Error('find_clinic.postal_code_errors.not_found');
    }

    return { lat: latitude, lng: longitude };
  }

  throw new Error('find_clinic.postal_code_errors.unreachable');
}
