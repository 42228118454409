import { Button, Link, Tooltip } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DEEP_BLUE } from '../../../constants/colors';

interface VirtualCheckInButtonProps {
  website?: string;
  onClickHandler?: () => void;
}

const VirtualCheckInButton = ({
  website,
  onClickHandler,
}: VirtualCheckInButtonProps) => {
  const { t } = useTranslation();

  if (!website) {
    return null;
  }

  return (
    <Tooltip
      title={t('find_clinic.tooltip.check_in_button')}
      disableInteractive
    >
      <Button
        variant="contained"
        component={Link}
        href={website}
        alignContent="center"
        sx={{
          bgcolor: DEEP_BLUE,
        }}
        onClick={onClickHandler}
      >
        {t('find_clinic.button.check_in_button_label')}
      </Button>
    </Tooltip>
  );
};

export default memo(VirtualCheckInButton);
