import { ComponentsOverrides } from '@mui/material';

const styleOverrides: ComponentsOverrides['MuiTab'] = {
  root: {
    'header div.header-tabs &': {
      minHeight: 'unset',
    },
  },
};

export const MuiTab = {
  styleOverrides,
};

const tabsStyleOverrides: ComponentsOverrides['MuiTabs'] = {
  root: {
    maxWidth: 'unset',
    minHeight: 'unset',
  },
};

export const MuiTabs = {
  styleOverrides: tabsStyleOverrides,
};
