import { memo } from 'react';
import { useParams } from 'react-router-dom';

import ConsultedRAAMQuestion from '../components/Question/ConsultedRAAMQuestion';
import PostalCodeQuestion from '../components/Question/PostalCodeQuestion';
import SelectClinic from '../components/Question/SelectClinicQuestion';
import { CONNECT_FLOW } from '../constants/connectNowFlow';
import { ConnectNowContextProvider } from '../hooks/useConnectNowContext';

export type QuestionType =
  | 'initial_assessment'
  | 'select_clinic'
  | 'find_clinic';

function getQuestionType(queryParam?: string): QuestionType {
  switch (queryParam) {
    case 'initial-assessment':
      return 'initial_assessment';
    case 'select-clinic':
      return 'select_clinic';
    case 'find-clinic':
      return 'find_clinic';
    default:
      return 'initial_assessment';
  }
}

function ConnectNowQuestion() {
  const { questionId } = useParams<{ questionId: string }>();
  const questionType = getQuestionType(questionId);

  switch (questionType) {
    case 'initial_assessment':
      return (
        <ConsultedRAAMQuestion
          text={CONNECT_FLOW[questionType].text}
          nextQuestionId={CONNECT_FLOW[questionType].nextQuestionId}
        />
      );

    case 'select_clinic':
      return (
        <SelectClinic
          text={CONNECT_FLOW[questionType].text}
          label={CONNECT_FLOW[questionType].label}
        />
      );
    case 'find_clinic':
      return <PostalCodeQuestion text={CONNECT_FLOW[questionType].text} />;

    default:
      throw new Error('Invalid question type');
  }
}

function ConnectNowPage() {
  return (
    <ConnectNowContextProvider>
      <ConnectNowQuestion />
    </ConnectNowContextProvider>
  );
}

export default memo(ConnectNowPage);
