import { styled } from '@mui/material';
import Card from '@mui/material/Card';

export const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: '28ch',
  flexBasis: '100%',
  borderRadius: '0.5rem',
  border: '1px solid #91A0b6',
  boxShadow: 'none',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    flexBasis: '30.4%',
  },
}));
