import { Stack } from '@mui/material';
import _ from 'lodash';
import { memo, useMemo } from 'react';
import { ClinicClickHandler } from '..';
import { DARK_GRAY } from '../../../constants/colors';
import { useClinicFilter } from '../../../hooks/useClinicFilter';
import { useFindClinicContext } from '../../../hooks/useFindClinicContext';
import { keyGenerator } from '../../../utils';
import { ClinicListItem } from './ClinicListItem';
import DFDIconLegend from './DFDIconLegend';

interface ClinicListItemProps {
  onClinicClick: ClinicClickHandler;
}

const ClinicList = ({ onClinicClick }: ClinicListItemProps) => {
  const { clinicData } = useFindClinicContext();
  const clinicFilter = useClinicFilter(true);

  const MemoizedClinicList = useMemo(() => {
    let displayIndex = -1;
    return _.map(clinicData, (clinic, index) => {
      if (clinicFilter(clinic)) {
        displayIndex++;
      }
      return (
        <ClinicListItem
          key={keyGenerator(clinic.name + index)}
          clinic={clinic}
          index={index}
          onClinicClick={onClinicClick}
          displayIndex={displayIndex}
        />
      );
    });
  }, [clinicData, clinicFilter, onClinicClick]);

  return (
    <>
      <Stack
        direction="column"
        overflow="auto"
        maxHeight="530px"
        width="100%"
        borderRadius={(theme) => theme.spacing(1)}
        border={`1px solid ${DARK_GRAY}`}
      >
        {MemoizedClinicList}
      </Stack>
      <DFDIconLegend />
    </>
  );
};

export default memo(ClinicList);
