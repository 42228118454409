import { useTranslation as useI18nextTranslation } from 'react-i18next';
import { en } from '../i18n/en';
import { fr } from '../i18n/fr';

// Modified from https://aishwarya2593.medium.com/create-wonders-with-advanced-typescript-types-525acf302770
type Access<T> = keyof {
  [Property in keyof T as T[Property] extends string
    ? Property
    : `${string & Property}.${string & Access<T[Property]>}`]: string;
};

export type I18nAccess = Access<typeof en & typeof fr>;

/** Returns the object from `react-i18next`'s `useTranslation` hook,
 * with improved typing to provide translation key auto-completion. */
export function useTranslation() {
  const { t, i18n, ready } = useI18nextTranslation();
  return { t, i18n, ready } as {
    t: (key: I18nAccess, options?: Parameters<typeof t>[2]) => string;
    i18n: typeof i18n;
    ready: typeof ready;
  };
}
