import { Marker, MarkerClusterer } from '@react-google-maps/api';
import _ from 'lodash';
import { memo, useMemo } from 'react';
import { ClinicClickHandler } from '..';
import M1 from '../../../assets/m1.png';
import M2 from '../../../assets/m2.png';
import M3 from '../../../assets/m3.png';
import M4 from '../../../assets/m4.png';
import M5 from '../../../assets/m5.png';
import mapMarkerSelected from '../../../assets/map-marker-selected.svg';
import mapMarkerUnselected from '../../../assets/map-marker-unselected.svg';
import { useClinicFilter } from '../../../hooks/useClinicFilter';
import { useFindClinicContext } from '../../../hooks/useFindClinicContext';
import { useScrollContext } from '../../../hooks/useScrollContext';
import { keyGenerator } from '../../../utils';

const labelStyleGenerator = (index: number) => {
  return {
    text: `${index + 1}`,
    fontWeight: 'bold',
    color: 'white',
  };
};

const MARKER_STYLES = [
  { url: M1, width: 53, height: 53 },
  { url: M2, width: 56, height: 56 },
  { url: M3, width: 66, height: 66 },
  { url: M4, width: 78, height: 78 },
  { url: M5, width: 90, height: 90 },
];

export interface ClinicMarkersProps {
  markerOnClickHandler: ClinicClickHandler;
}

const ClinicMarkers = ({ markerOnClickHandler }: ClinicMarkersProps) => {
  const { selectedRef } = useScrollContext();
  const { clinicData } = useFindClinicContext();
  const clinicFilter = useClinicFilter(true);

  return useMemo(
    () => (
      <MarkerClusterer
        styles={MARKER_STYLES}
        averageCenter
        ignoreHidden
        maxZoom={18}
        gridSize={25}
      >
        {(clusterer) => {
          let displayIndex = -1;
          return (
            <>
              {_.map(clinicData, (clinic, index) => {
                const visible = clinicFilter(clinic);
                if (visible) {
                  ++displayIndex;
                }
                return (
                  <Marker
                    key={keyGenerator(clinic.name, index)}
                    clusterer={clusterer}
                    position={clinic}
                    onClick={markerOnClickHandler(index, clinic)}
                    label={labelStyleGenerator(displayIndex)}
                    icon={{
                      url:
                        index === selectedRef
                          ? mapMarkerSelected
                          : mapMarkerUnselected,
                      scaledSize: new window.google.maps.Size(34, 44),
                      origin: new window.google.maps.Point(-2, -6),
                      labelOrigin: new window.google.maps.Point(16.5, 21),
                    }}
                    visible={visible}
                    title={clinic.name}
                  />
                );
              })}
            </>
          );
        }}
      </MarkerClusterer>
    ),
    [clinicData, clinicFilter, markerOnClickHandler, selectedRef]
  );
};

export default memo(ClinicMarkers);
