import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    enterTouchDelay={0}
    arrow
    leaveTouchDelay={3000}
    classes={{ popper: className }}
  />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.5)',
    },
    color: 'white',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '2px',
    backgroundColor: 'white',
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.5)',
    color: 'black',
    maxWidth: '13rem',
  },
}));
