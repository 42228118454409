import { Typography, TypographyProps, styled } from '@mui/material';

export const StyledSubContentTypography = styled(
  (props: TypographyProps<'p'>) => <Typography component="p" {...props} />
)(({ theme }) => ({
  fontSize: '0.75rem',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '1.25rem',
  color: theme.palette.primary.main,
}));
