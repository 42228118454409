import _ from 'lodash';
import {
  Dispatch,
  ReactNode,
  createContext,
  useCallback,
  useReducer,
} from 'react';
import { useClinicDistanceSliderRange } from '../hooks/useClinicDistanceSliderRange';
import { customClinicFilter } from '../hooks/useClinicFilter';
import { useFindClinicContext } from '../hooks/useFindClinicContext';
import { useScrollContext } from '../hooks/useScrollContext';

/** Represents the requirements a clinic has to meet to be shown to the user. */
export interface ClinicFilterType {
  /** Whether the clinic must accept virtual patients. */
  acceptsVirtual: boolean;
  /** Whether the clinic must currently be open. */
  isOpen: boolean;
  /** Maximum distance from user to clinic in kilometers. */
  maxDistance: number;
  /** Reducer to update clinic filter state. */
  updateClinicFilter: Dispatch<DirtyClinicFilterDispatchAction>;
  dirty: boolean;
}

const INITIAL_CLINIC_FILTER_VALUES: Omit<
  ClinicFilterType,
  'updateClinicFilter'
> = {
  acceptsVirtual: false,
  isOpen: false,
  // Show all clinics initially
  maxDistance: Infinity,
  dirty: false,
};

const INITIAL_CLINIC_FILTER: ClinicFilterType = {
  ...INITIAL_CLINIC_FILTER_VALUES,
  updateClinicFilter: () => {},
};

type ClinicFilterDispatchAction =
  | { type: 'reset' }
  | {
      type: 'maxDistance';
      value: number;
    }
  | {
      type: 'acceptsVirtual' | 'isOpen';
      value: boolean;
    };

type DirtyClinicFilterDispatchAction = ClinicFilterDispatchAction & {
  clean?: boolean;
};

const useClinicFilterReducer = (dfdOnly?: boolean) => {
  const { selectedRef, setSelectedRef } = useScrollContext();
  const { clinicData } = useFindClinicContext();
  const { getClinicDistance } = useFindClinicContext();
  const clinicFilter = customClinicFilter(getClinicDistance, null, false);

  const unselectIfFiltered = useCallback(
    (filter: Omit<ClinicFilterType, 'updateClinicFilter'>) => {
      if (selectedRef && !clinicFilter(filter)(clinicData[selectedRef])) {
        setSelectedRef(null);
      }
    },
    [selectedRef, clinicFilter, clinicData, setSelectedRef]
  );

  return (
    state: ClinicFilterType,
    action: DirtyClinicFilterDispatchAction
  ): ClinicFilterType => {
    if (action.type === 'reset') {
      const reset = { ...state, ...INITIAL_CLINIC_FILTER_VALUES };
      if (dfdOnly) {
        reset.acceptsVirtual = true;
      }
      return reset;
    }

    const { type, value } = action;
    const newState = { ...state, dirty: true, [type]: value };

    if (action.clean) {
      newState.dirty = false;
    }

    unselectIfFiltered(newState);

    return newState;
  };
};

export const ClinicFilterContext = createContext<ClinicFilterType>(
  INITIAL_CLINIC_FILTER
);

interface ClinicFilterProviderProps {
  children: ReactNode;
  dfdOnly?: boolean;
}

export const DEFAULT_MAX_DISTANCE = 150;

export function ClinicFilterContextProvider({
  children,
  dfdOnly,
}: ClinicFilterProviderProps) {
  const { sliderMin, sliderMax } = useClinicDistanceSliderRange();

  const [clinicFilter, dispatch] = useReducer(useClinicFilterReducer(dfdOnly), {
    ...INITIAL_CLINIC_FILTER,
    maxDistance:
      sliderMax === 0
        ? Infinity
        : _.clamp(DEFAULT_MAX_DISTANCE, sliderMin, sliderMax),
    acceptsVirtual: dfdOnly ? true : INITIAL_CLINIC_FILTER.acceptsVirtual,
  });

  return (
    <ClinicFilterContext.Provider
      value={{ ...clinicFilter, updateClinicFilter: dispatch }}
    >
      {children}
    </ClinicFilterContext.Provider>
  );
}
