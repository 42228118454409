import { Box } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { memo, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTE_URL } from '../../../constants/routes';
import { useTranslation } from '../../../hooks/useKeyedTranslation';
import { BackIconButton } from '../../Buttons/BackIconButton';
import { CloseButton } from '../../Buttons/CloseButton';
import { Transition } from '../../Transition';
import { ModalContent } from './ModalContent';
import { StyledResourceDialog } from './StyledResourceDialog';
import { StyledResourceDialogActions } from './StyledResourceDialogActions';

const ResourceModal = memo(() => {
  const { t } = useTranslation();
  const { resourceId: resourceIdParam } = useParams<'resourceId'>();
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    // handle invalid URL params by redirecting back to resource page
    if (resourceIdParam !== state?.resource?.id) {
      navigate(ROUTE_URL.resources_support.index, {
        replace: true,
      });
    }
  }, [state?.resource?.id, navigate, resourceIdParam]);

  if (!state?.resource) {
    navigate(-1);
    return null;
  }

  return (
    <StyledResourceDialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth="xl"
      role="dialog"
      aria-labelledby={state.resourceId}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id={state.resourceId}
        position="relative"
        textAlign="center"
        alignItems="center"
        fontStyle="italic"
        color="primary"
      >
        <Box marginRight="auto" px={2}>
          {t(state.resource.title)}
        </Box>
        <Box position="absolute" top="0" left="0" padding={1.5}>
          <BackIconButton handleClose={handleClose} />
        </Box>
      </DialogTitle>

      <DialogContent>
        <ModalContent content={state.resource.content} />
      </DialogContent>

      <StyledResourceDialogActions>
        <CloseButton handleClose={handleClose} />
      </StyledResourceDialogActions>
    </StyledResourceDialog>
  );
});

ResourceModal.displayName = 'ResourceModal';

export default ResourceModal;
