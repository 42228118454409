import { ClinicFilterType } from '../contexts/ClinicFilterContext';
import { ClinicDataType, isClinicOpen, isDFDClinic } from '../utils';
import { useClinicFilterContext } from './useClinicFilterContext';
import { useFindClinicContext } from './useFindClinicContext';
import { useScrollContext } from './useScrollContext';

export const customClinicFilter = (
  getClinicDistance: (clinic?: ClinicDataType | undefined) => number,
  selectedRef: number | null,
  ignoreDistance?: boolean
) => {
  return ({
    maxDistance,
    acceptsVirtual,
    isOpen,
  }: Omit<ClinicFilterType, 'updateClinicFilter'>) => {
    return (clinic: ClinicDataType, index?: number) => {
      return (
        // Always include clinic if it is selected
        index === selectedRef ||
        ((ignoreDistance || getClinicDistance(clinic) <= maxDistance) &&
          (!acceptsVirtual || isDFDClinic(clinic)) &&
          (!isOpen || isClinicOpen(clinic)))
      );
    };
  };
};

export const useClinicFilter = (ignoreDistance?: boolean) => {
  return customClinicFilter(
    useFindClinicContext().getClinicDistance,
    useScrollContext().selectedRef,
    ignoreDistance
  )(useClinicFilterContext());
};
