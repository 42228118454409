import { AccordionDetails, Stack } from '@mui/material';
import { Section } from '../../constants/ResourceSupport';
import { keyGenerator } from '../../utils';
import { ResourceSection } from './ResourceSection';

interface ResourceAccordionDetailsProps {
  sections: Section[];
}

export const ResourceAccordionDetails = ({
  sections,
}: ResourceAccordionDetailsProps) => {
  return (
    <AccordionDetails>
      <Stack spacing={2}>
        {sections.map((section, index) => (
          <ResourceSection section={section} key={keyGenerator(index)} />
        ))}
      </Stack>
    </AccordionDetails>
  );
};
