import { CardResourceType } from '../../../constants/ResourceSupport';
import { I18nAccess } from '../../../hooks/useKeyedTranslation';

interface ResourceIconProps {
  type: CardResourceType;
}

export const ResourceLinkText = ({ type }: ResourceIconProps): I18nAccess => {
  switch (type) {
    case 'resource':
      return 'resource_card.link.resource.label';
    case 'video':
      return 'resource_card.link.video.label';
    case 'pdf':
      return 'resource_card.link.pdf.label';
    default:
      return 'resource_card.link.video.label';
  }
};
