import { styled } from '@mui/material';
import { MEDIUM_BLUE } from '../../constants/colors';
import Link from '../Link';

export const InformationLink = styled(Link)(() => ({
  textDecoration: 'underline',
  fontWeight: 'bold',
  fontStyle: 'italic',
  color: MEDIUM_BLUE,
}));
