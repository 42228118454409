import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Dispatch, SetStateAction, memo, useCallback } from 'react';
import { ClinicClickHandler } from '..';
import { useFindClinicContext } from '../../../hooks/useFindClinicContext';
import MapSkeleton from '../../Loading/RectangularWaveSkeleton';
import ClinicMarkers from './ClinicMarkers';
import UserMarker from './UserMarker';

const DEFAULT_CONTAINER_STYLE = {
  width: '603px',
  height: '603px',
};

const DEFAULT_MAP_CENTER: google.maps.LatLngLiteral = {
  lat: 45.334904,
  lng: -75.724098,
};

interface MapProps {
  mapId?: string;
  apiKey: string;
  containerStyle?: {
    width: string;
    height: string;
  };
  zoom: number;
  setMap: Dispatch<SetStateAction<google.maps.Map | null>>;
  clinicOnClickHandler: ClinicClickHandler;
}

const MapComponent = ({
  mapId,
  apiKey,
  containerStyle,
  zoom,
  setMap,
  clinicOnClickHandler,
}: MapProps) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: mapId || 'google-map-script',
    googleMapsApiKey: apiKey,
  });
  const { coordinates, isLoading: isGeolocationLoading } =
    useFindClinicContext();

  const onLoad = useCallback(
    (mapRef: google.maps.Map) => {
      setMap(mapRef);
    },
    [setMap]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, [setMap]);

  if (loadError) {
    console.error(loadError);
    return <div>Map cannot be loaded right now, sorry.</div>; //TODO: replace with error component
  }

  return isLoaded && !isGeolocationLoading ? (
    <GoogleMap
      mapContainerStyle={containerStyle || DEFAULT_CONTAINER_STYLE}
      center={coordinates ?? DEFAULT_MAP_CENTER}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        minZoom: 2,
        isFractionalZoomEnabled: true,
      }}
    >
      <UserMarker position={coordinates} />
      <ClinicMarkers markerOnClickHandler={clinicOnClickHandler} />
    </GoogleMap>
  ) : (
    <MapSkeleton
      height={containerStyle?.height ?? DEFAULT_CONTAINER_STYLE.height}
      width={containerStyle?.width ?? DEFAULT_CONTAINER_STYLE.width}
    />
  );
};

export default memo(MapComponent);
